/**
 * WARNING: Do not modify
 *
 * API client generated by swagger-client-generator v1.2.0
 */

import { formatPathParams, formatParam, objectToUrlSearchParams } from "./common";


export enum DesignGraphType {
    tree = "tree",
    ring = "ring",
}

export enum Direction {
    _0 = "0",
    _1 = "1",
}

export enum FileTag {
    validation = "validation",
    pia_missing_inventory = "pia_missing_inventory",
    pia_network_adjustment = "pia_network_adjustment",
    pia_build_complete = "pia_build_complete",
    pia_evidence_capture = "pia_evidence_capture",
    pia_as_built = "pia_as_built",
    as_built = "as_built",
    defect = "defect",
}

export interface AuthConfig {
    access_token_scopes?: string[];
    application_id?: string;
    auth_service_url?: string;
    auth_version: string;
    authority?: string;
    login_scopes?: string[];
    redirect_uri?: string;
    tenant_id?: string;
}

export interface CableInfo {
    direction: Direction;
    is_end: boolean;
    is_start: boolean;
    name: string;
    size: number;
}

export interface ClosureInfo {
    n_pots: number;
    n_services: number;
    name: string;
    backhaul?: number;
}

export interface Edge {
    cables: CableInfo[];
    length: number;
    owner: string;
    source: string;
    target: string;
    type: string;
}

export interface FeatureAttribute {
    key: string;
    value: any;
}

export interface FeatureInfo {
    properties: FeatureAttribute[];
    bounds?: [number, number, number, number];
    geometry?: any;
    id?: string;
}

export interface File {
    author: string;
    data: string;
    design_id: string;
    filename: string;
    source_location: string;
    asset_id?: string;
    file_type?: string;
    pia_attachment_group_id?: number;
    pia_noi_id?: string;
    tag?: FileTag;
}

export interface Graph {
    edges: Edge[];
    nodes: Node[];
}

export interface Hyperlink {
    text: string;
    url: string;
}

export interface IntegrityError {
    error_message: string;
}

export interface LegendConfig {
    groups: LegendGroup[];
    items: { [key: string]: string };
    raster_sources: string[];
}

export interface LegendGroup {
    heading: string;
    id: string;
    keys: string[];
}

export interface MyConfig {
    layers: MyLayer[];
    legend: LegendConfig;
}

export interface MyLayer {
    layers: string[];
    name: string;
    area_asset_filter?: string;
    area_asset_filter_value?: string;
    geometry_column?: string;
    group?: string;
    has_identity: boolean;
    properties?: MyProperty[];
    results_limit: number;
    subtype?: string;
    table?: string;
    wfs?: string;
    wms?: WmsConfig;
}

export interface MyProperty {
    description: string;
    key: string;
    editable: boolean;
    hidden: boolean;
    link_text_template?: string;
    link_url_template?: string;
    searchable: boolean;
    values?: string[] | string;
}

export interface Node {
    cables: string[];
    closures: ClosureInfo[];
    distance: number;
    id: string;
    label: string;
    owner: string;
    type: string;
}

export interface Problem {
    detail: string;
    title: string;
    type: string;
}

export interface QueryResponse {
    results: { [key: string]: FeatureInfo[] };
}

export interface RenderedLayer {
    id: string;
    source: string;
    "source-layer": string;
    type: "raster" | "fill" | "line" | "circle" | "symbol" | "background";
    filter?: any;
    layout?: any;
    maxzoom?: number;
    metadata?: any;
    minzoom?: number;
    paint?: any;
}

export interface Source {
    tiles: string[];
    type: "raster" | "vector";
    attribution?: string;
    maxzoom?: number;
    tileSize?: number;
    tolerance?: number;
}

export interface StyleConfig {
    center: [number, number];
    glyphs: string;
    layers: RenderedLayer[];
    sources: { [key: string]: Source };
    sprite: string;
    version: 8;
    zoom: number;
}

export interface TopologyMetadata {
    design_type: DesignGraphType;
}

export interface TopologyResponse {
    graph: Graph;
    metadata: TopologyMetadata;
    integrity_errors?: IntegrityError[];
}

export interface WmsConfig {
    layers: string[];
    transparent: boolean;
}




export class GigamapClient {
    private baseUrl: string;
    private apiToken?: string;
    private globalFetchOptions: RequestInit;

    constructor(baseUrl: string, apiToken?: string, fetchOptions?: RequestInit) {
        this.baseUrl = baseUrl;
        this.apiToken = apiToken;
        this.globalFetchOptions = (fetchOptions === undefined) ? {} : fetchOptions;
    }

    get _headers(): Headers {
        let h = new Headers();
        if (this.apiToken) {
            h.append("Authorization", "Bearer " + this.apiToken);
        }
        return h;
    }

    /**
     *  Liveness check
     */
    async healthzLive(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/healthz/live", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Readiness check
     */
    async healthzReady(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/healthz/ready", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Gets a tile in MVT format
     */
    async getTile(x: number, y: number, z: number, layers?: string[]): Promise<Response> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/{z}/{x}/{y}.pbf", {
                "x": x,
                "y": y,
                "z": z,
            }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "layers": formatParam(layers, ","),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Get the style configuration
     */
    async getStyleConfig(exclude?: string[], conditional_styles?: boolean): Promise<StyleConfig> {
        let requestUrl = new URL(this.baseUrl + "/style.json", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "exclude": formatParam(exclude, ","),
            "conditional_styles": formatParam(conditional_styles, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get the list of XYZ tile coordinates for the named design
     */
    async getDesignTiles(community: string, maxzoom?: number, minzoom?: number): Promise<[number[], number[], number[]]> {
        let requestUrl = new URL(this.baseUrl + "/design-tiles", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "community": formatParam(community, undefined),
            "maxzoom": formatParam(maxzoom, undefined),
            "minzoom": formatParam(minzoom, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  a query
     */
    async query(x: number, y: number, distance: number, limit?: number): Promise<QueryResponse> {
        let requestUrl = new URL(this.baseUrl + "/query", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "x": formatParam(x, undefined),
            "y": formatParam(y, undefined),
            "distance": formatParam(distance, undefined),
            "limit": formatParam(limit, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Search for features matching a term.
     */
    async search(term: string, lat: number, lon: number): Promise<QueryResponse> {
        let requestUrl = new URL(this.baseUrl + "/search", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "term": formatParam(term, undefined),
            "lat": formatParam(lat, undefined),
            "lon": formatParam(lon, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get file from S3
     */
    async getFile(file_id?: string): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/file", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "file_id": formatParam(file_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Search for map locations by partial address or postcode
     */
    async locationSearch(term: string, lat?: number, lon?: number): Promise<FeatureInfo[]> {
        let requestUrl = new URL(this.baseUrl + "/location_search", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "term": formatParam(term, undefined),
            "lat": formatParam(lat, undefined),
            "lon": formatParam(lon, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get the configuration
     */
    async getConfig(): Promise<MyConfig> {
        let requestUrl = new URL(this.baseUrl + "/config.json", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Create files on Gigastore
     */
    async gigastoreCreateFiles(body?: File[]): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/create_files", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "POST", headers: this._headers }, this.globalFetchOptions
        );

        fetchOptions.headers.append("Content-Type", "application/json");
        fetchOptions["body"] = JSON.stringify(body);
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Delete file from S3
     */
    async gigastoreDeleteFiles(design_id: string, file_id?: string): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/delete_files", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "DELETE", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "design_id": formatParam(design_id, undefined),
            "file_id": formatParam(file_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Retrieve asset Ids in path and return a GeoJson representation of such path
     */
    async getCablePathBetweenAssets(design_id: string, target_asset_id: string): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/get_cable_path_between_assets", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "design_id": formatParam(design_id, undefined),
            "target_asset_id": formatParam(target_asset_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve splicing diagram (SVG) for a closure
     */
    async getSplicingDiagramForClosure(design_id: string, closure_id: string): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/get_splicing_diagram", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "design_id": formatParam(design_id, undefined),
            "closure_id": formatParam(closure_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

    /**
     *  Retrieve cabinet and closure names
     */
    async getCabinetAndClosureName(design_id: string, closure_id: string): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/get_cabinet_and_closure_name", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "design_id": formatParam(design_id, undefined),
            "closure_id": formatParam(closure_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve the Straight Line Diagram JSON from Asset DB
     */
    async getStraightLineDiagramJsonFromAssetDb(cabinet_name: string, design_id: string): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/get_straight_line_diagram_json", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "cabinet_name": formatParam(cabinet_name, undefined),
            "design_id": formatParam(design_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve the Cabinet Areas from Asset DB
     */
    async getCabinetAreasFromAssetDb(): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/get_cabinet_areas", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Get the topology for a cabinet (proxy for Asset DB)
     */
    async getTopology(design_id: string, feeder_node_id?: string, valid_at?: Date | string): Promise<TopologyResponse> {
        let requestUrl = new URL(this.baseUrl + "/topology", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "design_id": formatParam(design_id, undefined),
            "feeder_node_id": formatParam(feeder_node_id, undefined),
            "valid_at": formatParam(valid_at, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  List the feeder nodes for a specified cab area (proxy for Asset DB)
     */
    async getFeederNodes(design_id: string): Promise<any> {
        let requestUrl = new URL(this.baseUrl + "/feeder_nodes", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        requestUrl.search = objectToUrlSearchParams({
            "design_id": formatParam(design_id, undefined),
        }).toString();
        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Retrieve a Pole by its ID (proxy for Asset Management)
     */
    async getPoleFromAssetManagement(pole_id: string): Promise<any> {
        let requestUrl = new URL(
            this.baseUrl + formatPathParams(
                "/pole/{pole_id}", {
                "pole_id": pole_id,
            }
            ),
            window.location.origin
        );

        let fetchOptions = Object.assign(
            {}, { method: "GET", headers: this._headers }, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }

    /**
     *  Generate a user token for Asset DB
     */
    async generateUserTokenForAssetDb(): Promise<Response> {
        let requestUrl = new URL(this.baseUrl + "/generate_user_token_for_asset_db", window.location.origin);

        let fetchOptions = Object.assign(
            {}, { method: "POST", headers: this._headers }, this.globalFetchOptions
        );

        const response = await fetch(requestUrl.toString(), fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        throw response;
    }

}

