import Vue, { CreateElement } from "vue";
import { Component } from "vue-property-decorator";
import jwt_decode from "jwt-decode";

import initAuth, { getAzureUserData, login, UserIdPayload, getAzureAccessToken } from "@/oauth";
import { getAuthConfigs } from "@/auth-helpers";
import { TOKEN_KEY, TokenPayload, messages, authApiFactory } from "@/services";
import { apiUrl, getAuthServiceUrl } from "@/appconfig";
import { VCard, VCardTitle, VCardActions, VSpacer, VBtn, VSnackbar, VTextField } from "vuetify/lib";
import { setMsalConfigs } from "@/store";
import { objectToUrlSearchParams } from "@/services/common";

@Component({
    components: {
        VCard,
        VCardTitle,
        VCardActions,
        VSpacer,
        VBtn,
        VSnackbar,
        VTextField,
    },
})
export default class Login extends Vue {
    loginErrorMessage: string;
    loginFailed: boolean;
    userName: string;
    password: string;

    data() {
        return {
            loginErrorMessage: "",
            loginFailed: false,
            userName: "",
            password: "",
        };
    }

    async handleLogin(token: string) {
        // POST token to login endpoint to set token cookie
        await fetch(apiUrl() + "/login", {
            method: "POST",
            body: objectToUrlSearchParams({ token, redirect: "" }),
            credentials: "include",
            mode: "cors",
            redirect: "manual",
        });
    }

    async mounted() {
        let authConfigs: any = null;
        try {
            authConfigs = await getAuthConfigs(apiUrl() + "/authConfigs");

            setMsalConfigs(this.$store, authConfigs);
            initAuth(this.$store.state.msalConfig, this.$store.state.loginScopes, this.$store.state.accessTokenScopes);
        } catch (error) {
            messages.$emit("apiError", error.message);
            return;
        }
        // First, attempt to get an Azure token. If successful, use it and load the page
        const token = await getAzureAccessToken();
        if (token) {
            await this.handleLogin(token);
            this.handleV2Token(null);
        } else {
            // If no Azure token is found, try to retrieve a cached or existing auth-service token
            // If no auth-service token exists, the user will be redirected to the login page
            const authServiceApi = authApiFactory(this.$store.state.authServiceUrl, { credentials: "include" });
            try {
                const tokenResponse = await authServiceApi.getToken();
                const token = await tokenResponse.text();
                await this.handleLogin(token);
                this.handleV1Token(token);
            } catch (errorResponse) {
                if (errorResponse.status !== 422) {
                    throw errorResponse;
                }
            }
        }
    }

    render(h: CreateElement) {
        return (
            <div id="loginView">
                <v-card class="login-card">
                    <form onSubmit={this.authV1SignIn}>
                        <v-card-title>
                            <h3>Login using username and password</h3>
                        </v-card-title>
                        <div class="mx-4">
                            <v-text-field
                                id="username-input"
                                label="Username"
                                v-model={this.userName}
                                required
                            ></v-text-field>
                            <v-text-field
                                id="password-input"
                                label="Password"
                                type="password"
                                v-model={this.password}
                                required
                            ></v-text-field>
                        </div>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text id="login" color="primary" type="submit">
                                Login
                            </v-btn>
                        </v-card-actions>
                    </form>
                </v-card>
                <v-card class="azure-login-card d-flex flex-column align-center justify-center">
                    <form onSubmit={this.authV2SignIn}>
                        <v-card-actions>
                            <v-btn text id="azure-login" color="primary" type="submit">
                                <img src="/img/icons/microsoft-logo.svg" alt="Microsoft Logo" />
                                Sign in with Microsoft
                            </v-btn>
                        </v-card-actions>
                    </form>
                </v-card>

                <v-snackbar bottom v-model={this.loginFailed} timeout={60000}>
                    <span>{this.loginErrorMessage}</span>
                    <v-btn text color="primary" onClick={() => (this.loginFailed = false)}>
                        Close
                    </v-btn>
                </v-snackbar>
            </div>
        );
    }

    async authV1SignIn(event: Event) {
        event.preventDefault();
        const authApi = authApiFactory(getAuthServiceUrl(), { credentials: "include" });
        try {
            const tokenResponse = await authApi.createToken(this.userName, this.password);
            const token = await tokenResponse.text();
            await this.handleLogin(token);
            this.handleV1Token(token);
        } catch (errorResponse) {
            if (errorResponse.status === 401) {
                this.loginErrorMessage = "Login failed: Username or password incorrect";
                this.loginFailed = true;
            } else {
                throw errorResponse;
            }
        }
    }

    async authV2SignIn(event: Event) {
        event.preventDefault();
        const loginResponse = await login(this.$store.state.loginScopes);
        if (typeof loginResponse === "string") {
            this.loginErrorMessage = loginResponse;
            this.loginFailed = true;
        } else {
            this.handleV2Token(loginResponse);
        }
    }

    async handleV1Token(token: string) {
        const redirectTo = (this.$route.query.redirect as string) || "/";
        const payload: TokenPayload = jwt_decode(token);
        localStorage.setItem(TOKEN_KEY, token);
        messages.$emit("login", payload);
        this.$router.push(redirectTo);
    }

    async handleV2Token(userData: UserIdPayload | null) {
        const redirectTo = (this.$route.query.redirect as string) || "/";
        if (!userData) {
            userData = getAzureUserData();
        }
        messages.$emit("login", userData);
        this.$router.push(redirectTo);
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-69e12184/login.tsx" });