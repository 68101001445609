import { CreateElement } from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { getUserFromAnyAuth } from "../auth-helpers";
import {
    VBtn,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VDivider,
    VSpacer,
    VTextField,
    VFileInput,
    VProgressCircular,
} from "vuetify/lib";

import { gigamapApiFactory } from "@/services";
import { accessTokenFromStore, showSnackbar } from "@/utils";
import { FeatureAttribute } from "@/services/gigamap";

interface GigastoreFileDeleteProps {
    "v-model": boolean;
    "designId": string;
    "file_data"?: FeatureAttribute[];
}

@Component({
    components: {
        VBtn,
        VDialog,
        VCard,
        VCardTitle,
        VCardText,
        VCardActions,
        VDivider,
        VSpacer,
        VTextField,
        VFileInput,
        VProgressCircular,
    },
})
export default class GigastoreFileDelete extends tsx.Component<GigastoreFileDeleteProps> {
    @Prop() value: boolean;
    @Prop() designId: string;
    @Prop() file_data?: FeatureAttribute[];

    isDeleting: boolean;
    data() {
        return {
            isDeleting: false,
        };
    }

    render(h: CreateElement): JSX.Element | undefined {
        const buttonContent = this.isDeleting ? (
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        ) : (
            "Delete"
        );
        return (
            <div>
                <v-dialog v-model={this.value} max-width="500px" persistent>
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2">Delete File/Photo</v-card-title>

                        <v-card-text class="pa-6">Are you sure you want to delete the file?</v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions class="justify-center">
                            <v-spacer></v-spacer>
                            <v-btn onClick={this.hideDialog}>Close</v-btn>
                            <v-btn disabled={this.isDeleting} onClick={this.handleGigastoreDelete} class="error">
                                {buttonContent}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        );
    }

    async handleGigastoreDelete() {
        this.isDeleting = true;
        const userData = await this.getUser();
        const fileAuthor = this.file_data?.find(data => {
            return data.key === "author";
        });
        // Checking if the user has the Developer role or the Azure roleGigamapPublisher_GigamapDeleteAnyFiles role.
        // If the user has either of these roles, they can delete any files.
        if (
            !userData?.roles?.includes("Developer") &&
            !userData?.roles?.includes("roleGigamapPublisher_GigamapDeleteAnyFiles")
        ) {
            if (fileAuthor?.value.toLowerCase() != userData?.uid.toLowerCase()) {
                this.hideDialogAndShowSnackbar("You are not authorized to delete another user's files");
                return;
            }
        }
        try {
            const file_id = this.file_data?.find(data => {
                return data.key === "id";
            });
            const accessToken = accessTokenFromStore(this.$store) ?? "";
            const gigamapClient = gigamapApiFactory(accessToken);
            await gigamapClient.gigastoreDeleteFiles(this.designId, file_id?.value);
            this.hideDialogAndShowSnackbar("File deleted successfully");
        } catch (error) {
            if (error.status) {
                console.error(error);
            }
            this.hideDialogAndShowSnackbar("Failed to delete: unknown server error");
        }
    }

    hideDialog() {
        this.$emit("input", false);
        this.isDeleting = false;
    }

    hideDialogAndShowSnackbar(message: string | undefined) {
        this.hideDialog();
        if (message) {
            showSnackbar(message);
        }
    }

    async getUser() {
        return await getUserFromAnyAuth();
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-c9b2f916/gigastore_file_delete.tsx" });