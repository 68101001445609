import Vue from "vue";
import jwt_decode from "jwt-decode";

import { AuthServiceClient } from "./auth";
import { GigamapClient } from './gigamap';
import { apiUrl, assetDbUrl } from "@/appconfig";
import { AssetDatabaseClient } from "./asset_db";

export interface TokenPayload {
    uid: string;
    exp: number;
    roles: string[];
}

export const TOKEN_KEY = "jwt";
export const messages = new Vue();

// This is only for Auth Service
export const getAuthToken = (): [string | undefined, TokenPayload | undefined] => {
    const jwt = localStorage.getItem(TOKEN_KEY);
    if (jwt) {
        const payload: TokenPayload = jwt_decode(jwt);
        const now = Date.now();
        // If token expired
        if (payload.exp < now / 1000) {
            return [undefined, undefined];
        }
        return [jwt, payload];
    }
    return [undefined, undefined];
};

export function authApiFactory(authApiUrl: string, fetchOptions?: RequestInit): AuthServiceClient {
    const [token] = getAuthToken();
    return new AuthServiceClient(authApiUrl, token, fetchOptions);
}

export function gigamapApiFactory(token: string): GigamapClient {
    return new GigamapClient(apiUrl(), token);
}

export function assetDbApiFactory(token: string): AssetDatabaseClient {
    return new AssetDatabaseClient(assetDbUrl(), token);
}
