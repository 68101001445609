import * as tsx from "vue-tsx-support";
import * as d3 from "d3";
import { Component, Watch } from "vue-property-decorator";
import { VAutocomplete, VBtn, VContainer, VFlex, VIcon, VLayout, VRadio, VRadioGroup, VSpacer } from "vuetify/lib";
import {
    Edge,
    ClosureInfo,
    Node,
    CableInfo,
    TopologyResponse,
    Direction,
    DesignGraphType,
    Graph,
} from "@/services/gigamap";
import { TrenchType } from "@/services/asset_db";
import svgPanZoom from "svg-pan-zoom";
import Hammer from "hammerjs";
import { CreateElement } from "vue";
import { accessTokenFromStore } from "@/utils";
import { gigamapApiFactory, messages } from "@/services";

interface TreeNode {
    id: string;
    name: string;
    closures: ClosureInfo[];
    cables: string[];
    children?: any[];
    type: string;
    is_pia: boolean;
}

interface FeederNode {
    id: string;
    name: string;
}

export interface TopologyEdgeType {
    [key: string]: Edge;
}

export interface CableLengthType {
    [key: string]: number;
}

export interface Cable {
    name: string;
    size: number;
    start?: string;
    end?: string;
}

export interface CableData {
    [key: string]: Cable;
}

enum TouchState {
    "NO_TOUCH" = 0,
    "TOUCH_START",
    "TOUCH_MOVE",
    "TOUCH_END",
}

function getArrayDepth(node: TreeNode, depth: number = 0): number {
    if (node.children === undefined || node.children.length === 0) {
        return depth;
    }
    const maxDepth: number = Math.max(...node.children.map((child: TreeNode) => getArrayDepth(child, depth + 1)));

    return maxDepth;
}

function getArrayBreadth(node: TreeNode) {
    if (node.children === undefined || node.children.length === 0) {
        return 1;
    }

    const x: any = [...node.children.map((child: TreeNode) => getArrayBreadth(child))].reduce((a, b) => a + b, 0);

    return x;
}

function createD3Line(
    edgeId: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    stroke: string,
    strokeWidth: number,
): any {
    return d3
        .select("#" + edgeId)
        .append("line")
        .attr("x1", x1)
        .attr("y1", y1)
        .attr("x2", x2)
        .attr("y2", y2)
        .style("stroke", stroke)
        .style("stroke-width", strokeWidth);
}

function createD3RingLine(edgeId: string, stroke: string, strokeWidth: number): any {
    return d3
        .select("#" + edgeId)
        .append("line")
        .style("stroke", stroke)
        .style("stroke-width", strokeWidth);
}

@Component({
    components: {
        VContainer,
        VLayout,
        VFlex,
        VAutocomplete,
        VRadioGroup,
        VRadio,
        VSpacer,
        VBtn,
        VIcon,
    },
})
export default class TopologyDiagram extends tsx.Component<{}> {
    selectedCabinetId?: string;
    dataLoaded: boolean = false;
    feederNodes: FeederNode[] = [];
    cabinetTopology?: TopologyResponse;
    selectedFeederNode?: string;
    cabinetName: string | undefined;
    feederNodesPresent: boolean = false;
    nodeLabelling: string;
    errorOccurred: boolean = false;
    errorMessage: string = "";
    isLegendDisplayed: boolean = false;
    visitedNodes: Node[];
    topologyEdgeData: TopologyEdgeType;
    cableData: CableLengthType;
    cableDataByName: CableData;
    zoomPanOptions: SvgPanZoom.Options;
    zoomPan: SvgPanZoom.Instance;
    displayLegend: boolean;
    cableSelected?: string;
    nodeSelected?: string;
    hoveredCable?: string;
    nodeById: { [key: string]: Node };
    backendUrl: string;
    hammer: any;
    previousTouchState: TouchState;
    svgRingCableOffsets: { [cableID: string]: number };

    data() {
        return {
            selectedCabinetId: this.selectedCabinetId,
            dataLoaded: this.dataLoaded,
            cabinetTopology: this.cabinetTopology,
            selectedFeederNode: this.selectedFeederNode,
            cabinetName: this.cabinetName,
            feederNodesPresent: this.feederNodesPresent,
            feederNodes: this.feederNodes,
            nodeLabelling: this.nodeLabelling,
            errorOccurred: this.errorOccurred,
            errorMessage: this.errorMessage,
            isLegendDisplayed: this.isLegendDisplayed,
            visitedNodes: [],
            topologyEdgeData: {},
            cableData: {},
            zoomPanOptions: {
                zoomScaleSensitivity: 0.4,
                minZoom: 0.2,
                maxZoom: 5,
                fit: 1,
                center: 1,
                dblClickZoomEnabled: false,
            },
            displayLegend: false,
            cableSelected: undefined,
            nodeSelected: undefined,
            hoveredCable: undefined,
            cableDataByName: {},
            nodeById: {},
            hammer: {},
            previousTouchState: TouchState.NO_TOUCH,
            svgRingCableOffsets: {},
        };
    }

    async created() {
        const designId = this.$route.params.designId;
        if (designId) {
            this.selectedCabinetId = designId;
            await this.loadFeederNodes();
            await this.loadTopology();
        }
    }

    async onFeederNodeChange() {
        if (this.selectedFeederNode) {
            await this.loadTopology();
        }
    }
    async onFeederNodeInput(value: string | null | undefined) {
        /// When field is cleared, revert to whole cabinet topology
        if (!value) {
            this.selectedFeederNode = undefined;
            await this.loadTopology();
        }
    }

    async loadTopology() {
        if (this.selectedCabinetId) {
            const feederNode = this.selectedFeederNode;
            const designId = this.selectedCabinetId;
            try {
                const token = accessTokenFromStore(this.$store) ?? "";
                const client = gigamapApiFactory(token);
                const topology = await client.getTopology(designId, feederNode === "" ? undefined : feederNode);
                this.cabinetTopology = topology;
                this.dataLoaded = true;
                if (topology && topology.graph && topology.graph.nodes.length > 0) {
                    const cabinetName = topology.graph.nodes[0].label;
                    this.cabinetName = cabinetName;
                }
            } catch (e) {
                const error = await e.json();
                if (feederNode) {
                    messages.$emit("apiError", error.detail);
                    this.selectedFeederNode = undefined;
                    this.dataLoaded = false;
                } else {
                    messages.$emit("apiError", [`Failed to get topology for cabinet ${designId}`, error.detail]);
                }
            }
        }
        this.resetZoomPan();
    }

    async loadFeederNodes() {
        if (this.selectedCabinetId) {
            const cabinetId = this.selectedCabinetId;
            // TODO: Add a loading bar specifically for feeder nodes
            // Due to feeder nodes loading faster then a cabinets topology
            // it can cancel out the loading bar, usually this is already loaded before the cab area though
            // commit(mutations.setLoading)(context, true);
            try {
                const token = accessTokenFromStore(this.$store) ?? "";
                const client = gigamapApiFactory(token);
                const feederNodes = await client.getFeederNodes(cabinetId);
                if (feederNodes) {
                    //this.feederNodes = feederNodes;
                    this.feederNodes = Object.entries(feederNodes).map(([id, name]) => ({
                        id,
                        name: name as string,
                    }));
                }
            } catch (e) {
                messages.$emit("apiError", `Failed to get feeder nodes for cabinet ${this.selectedCabinetId}`);
            }
        }
    }

    @Watch("nodeLabelling")
    updateLabels() {
        d3.selectAll(".service_point_count").attr(
            "display",
            this.nodeLabelling === "service_point_count" ? "inherit" : "none",
        );
        d3.selectAll(".closure_names").attr("display", this.nodeLabelling === "closure_names" ? "inherit" : "none");
        d3.selectAll(".endpoint_name").attr("display", this.nodeLabelling === "endpoint_name" ? "inherit" : "none");
    }

    @Watch("cabinetTopology")
    onTopologyLoaded() {
        if (this.cabinetTopology) {
            const graph = this.cabinetTopology.graph;
            const designType = this.cabinetTopology.metadata.design_type;

            this.cableData = {};

            const nodes: any[] = [];
            const links: any[] = [];

            graph.nodes.forEach((node: Node) => {
                this.nodeById[node.id] = node;
                nodes.push({
                    id: node.id,
                    name: node.label,
                    closures: node.closures,
                    cables: node.cables,
                    type: node.type,
                    is_pia: node.owner === "Gigaclear" || node.owner === "N/A" ? false : true,
                });
            });

            graph.edges.forEach((e: Edge) => {
                const edgeId1: string = "uuid_" + e.source + "_" + e.target;
                const edgeId2: string = "uuid_" + e.target + "_" + e.source;

                this.topologyEdgeData[edgeId1] = e;
                this.topologyEdgeData[edgeId2] = e;

                const edgeLength = e.length;
                e.cables.forEach((cable: CableInfo) => {
                    const currentLength = this.cableData[cable.name] ? this.cableData[cable.name] : 0;
                    this.cableData[cable.name] = currentLength + edgeLength;
                    if (!(cable.name in this.cableDataByName)) {
                        this.cableDataByName[cable.name] = {
                            name: cable.name,
                            size: cable.size,
                        };
                    }

                    const [start, end] = cable.direction === Direction._0 ? [e.target, e.source] : [e.source, e.target];

                    if (cable.is_start) {
                        this.cableDataByName[cable.name].start = start;
                    }
                    if (cable.is_end) {
                        this.cableDataByName[cable.name].end = end;
                    }
                });

                links.push({
                    source: e.source,
                    target: e.target,
                });
            });

            if (designType === DesignGraphType.tree) {
                this.updateSVGTree(graph);
            } else if (designType === DesignGraphType.ring) {
                this.updateSVGRing(nodes, links);
            }
        }
    }

    getTreeData(nodes: Node[], edges: Edge[]) {
        const accessNodes = nodes.filter((n: Node) => {
            if (n.type === "AccessCabinet") {
                return n;
            }
        });
        const gatewayNode = nodes.filter((n: Node) => {
            if (n.type === "GatewayCabinet") {
                return n;
            }
        });
        const rootNode = gatewayNode.length === 1 ? gatewayNode[0] : accessNodes[0];
        console.assert(accessNodes.length === 1, `There is more than one access cabinet: ${this.selectedCabinetId}`);
        this.visitedNodes = [];
        const tree = this.getTreeItem(rootNode, nodes, edges);
        return tree;
    }

    getTreeItem(node: Node, nodes: Node[], edges: Edge[]) {
        const treeItem: TreeNode = {
            id: node.id,
            name: node.label,
            closures: node.closures,
            cables: node.cables,
            type: node.type,
            children: [],
            is_pia: node.owner === "Gigaclear" || node.owner === "N/A" ? false : true,
        };
        this.visitedNodes.push(node);
        // List of edges going FROM the 'node'
        // Keep track of visted nodes , edges don't have direction. check edge.target as well
        const nodeEdges = edges.filter((edge: Edge) => edge.source === node.id || edge.target === node.id);
        const connectingNodes: any = [];
        nodeEdges.forEach((e: Edge) => {
            nodes.forEach((n: Node) => {
                if ((e.target === n.id || e.source === n.id) && !this.visitedNodes.includes(n)) {
                    connectingNodes.push(n);
                }
            });
        });

        if (!treeItem.children) {
            treeItem.children = [];
        }
        const children = treeItem.children as TreeNode[];

        connectingNodes.forEach((n: Node) => {
            const child = this.getTreeItem(n, nodes, edges);
            children.push(child);
        });

        // Add a virtual node for each backhaul closure
        node.closures.forEach((closure: ClosureInfo, index: number) => {
            if (closure.backhaul !== undefined && closure.backhaul > 0) {
                const child: TreeNode = {
                    id: `${node.id}_backhaul${index}`,
                    name: `Backhaul - ${closure.backhaul}`,
                    closures: [],
                    type: "Backhaul",
                    children: [],
                    cables: [],
                    is_pia: node.owner === "Gigaclear" ? false : true,
                };
                children.push(child);
            }
        });

        if (children.length === 0) {
            delete treeItem.children;
        } else {
            children.sort((a: TreeNode, b: TreeNode) => {
                return a.name > b.name ? 1 : -1;
            });
        }
        return treeItem;
    }

    displayTooltip(data: any, event: MouseEvent | TouchEvent) {
        let left;
        let top;

        if (this.previousTouchState !== TouchState.NO_TOUCH) {
            const svg: any = document.getElementById("tree");
            const bboxSvg = svg.getBoundingClientRect();
            left = bboxSvg.left;
            top = bboxSvg.top;
        } else {
            if (event instanceof MouseEvent) {
                left = event.pageX + 20;
                top = event.pageY - 70;
            } else if (event instanceof TouchEvent && event.touches.length > 0) {
                left = event.touches[0].pageX + 20;
                top = event.touches[0].pageY - 70;
            }
        }

        d3.select("#tooltip")
            .style("opacity", "0.8")
            .style("left", left + "px")
            .style("top", top + "px");

        const tooltip: any = document.getElementById("tooltip");
        const bbox = tooltip.getBoundingClientRect();

        if (bbox.right >= window.innerWidth && this.previousTouchState === TouchState.NO_TOUCH) {
            if (event instanceof MouseEvent) {
                d3.select("#tooltip")
                    .style("opacity", "0.8")
                    .style("left", event.pageX - bbox.width - 20 + "px")
                    .style("top", event.pageY - 70 + "px");
            } else if (event instanceof TouchEvent && event.touches.length > 0) {
                d3.select("#tooltip")
                    .style("opacity", "0.8")
                    .style("left", event.touches[0].pageX - bbox.width - 20 + "px")
                    .style("top", event.touches[0].pageY - 70 + "px");
            }
        }

        d3.selectAll("#tooltip").html(data);
    }

    nodeCircleTypeStyles(type: string, isPIA: string) {
        const classes = ["node"];
        switch (type) {
            case "AccessCabinet": {
                classes.push("access_cabinet_color");
                break;
            }
            case "BuildingAggregationPoint": {
                classes.push("building_aggregation_point_color");
                break;
            }
            case "Chamber": {
                classes.push("chamber_color");
                break;
            }
            case "TrenchJunction": {
                classes.push("junction_color");
                break;
            }
            case "DropCabinet": {
                classes.push("drop_cabinet_color");
                break;
            }
            case "GatewayCabinet": {
                classes.push("gateway_cabinet_color");
                break;
            }
            case "Backhaul": {
                classes.push("backhaul_color");
                break;
            }
            case "Pole": {
                classes.push("pole_color");
                break;
            }
            default: {
                classes.push("other_color");
            }
        }
        if (isPIA) {
            classes.push("pia_asset");
        }
        return classes.join(" ");
    }

    nodeLabelStyles(label: any) {
        label
            .attr("font-weight", "bold")
            .attr("stroke", "black")
            .attr("stroke-width", "2")
            .attr("fill", "white")
            .attr("font-size", "2em")
            .attr("text-anchor", "middle")
            .attr("pointer-events", "none");
    }

    linkTextStyles(linkGroupText: any) {
        linkGroupText
            .attr("font-weight", "bold")
            .attr("font-size", "1.5em")
            .attr("text-anchor", "middle")
            .attr("pointer-events", "none");
    }

    updateSVGRing(nodes: any[], links: any[]) {
        const ringWidth = 500;
        const ringHeight = 500;
        const simulation = d3
            .forceSimulation(nodes)
            .force(
                "link",
                d3.forceLink(links).id((d: any) => d.id),
            )
            .force("center", d3.forceCenter(ringWidth / 2, ringHeight / 2))
            .force("collide", d3.forceCollide().radius(5))
            .force(
                "r",
                d3.forceRadial(
                    (d: any) => {
                        // set radius of ring based on number of nodes and fix cable length
                        // to ensure label text is visible on links between nodes
                        const noOfNodes = nodes.length;
                        const cableLength = 275;
                        const radius = (noOfNodes * cableLength) / (2 * Math.PI);
                        if (nodes.length <= 4) {
                            // if it's a square
                            return 600;
                        } else {
                            return radius;
                        }
                    },
                    ringWidth / 2,
                    ringHeight / 2,
                ),
            );

        // Reset all the nodes/links and text (labels) from previous cabs
        d3.selectAll("g.nodes text").remove();
        d3.selectAll("g.node").remove();
        d3.selectAll("g.link").remove();
        d3.selectAll("g.links text").remove();

        // select container element
        const linksContainer: any = d3.select("svg .links");
        const nodesContainer: any = d3.select("svg .nodes");

        // append nodes and links (edges)
        const linkGroup: any = linksContainer.selectAll("g.link").data(links).enter().append("g").classed("link", true);

        const node: any = nodesContainer.selectAll("g.node").data(nodes).enter().append("g").classed("node", true);

        // style node attributes
        node.append("circle")
            .attr("r", 25)
            .attr("class", (d: any) => {
                return this.nodeCircleTypeStyles(d.type, d.is_pia);
            })
            // Append 'uuid_' (as css selectors can't handle ones that start with a number)
            .attr("id", (d: any) => "uuid_" + d.id);
        // on events

        const label = node
            .append("text")
            .text((d: any) => (d.type === "AccessCabinet" ? d.name : ""))
            .attr("y", 10); // drop text down a bit
        this.nodeLabelStyles(label);

        label
            .append("tspan")
            .classed("endpoint_name", true)
            .text((d: any) => {
                return d.type === "TrenchJunction" || d.type === "AccessCabinet" ? "" : d.name;
            })
            .attr("display", this.nodeLabelling === "endpoint_name" ? "inherit" : "none");

        label
            .append("tspan")
            .classed("closure_names", true)
            .text((d: any) => {
                const prefix = d.type === "AccessCabinet" ? " - " : "";
                if (d.closures.length !== 0) {
                    return (
                        prefix +
                        d.closures
                            .map((c: ClosureInfo) => c.name)
                            .sort((a: string, b: string) => a.localeCompare(b))
                            .join(", ")
                    );
                }
            })
            .attr("display", this.nodeLabelling === "closure_names" ? "inherit" : "none");

        label
            .append("tspan")
            .classed("service_point_count", true)
            .text((d: any) => {
                const prefix = d.type === "AccessCabinet" ? " - " : "";
                if (d.closures.length !== 0) {
                    const totalSP = d.closures
                        .map((c: ClosureInfo) => c.n_services)
                        .reduce((nServices: number, sum: number) => nServices + sum, 0);
                    return prefix + `${totalSP} SP`;
                }
            })
            .attr("display", this.nodeLabelling === "service_point_count" ? "inherit" : "none");

        // Visual data BETWEEN the nodes
        linkGroup.attr("id", (e: any) => {
            const sourceId = e.source.id;
            const targetId = e.target.id;
            return "uuid_" + sourceId + "_" + targetId;
        });

        // Text displayed for the visual data between the nodes
        const linkGroupText = linksContainer
            .selectAll("text")
            .data(links)
            .enter()
            .append("text")
            .text((d: any) => {
                const edgeId: string = "uuid_" + d.source.id + "_" + d.target.id;
                const edgeData = this.topologyEdgeData[edgeId];
                if (edgeData === undefined) {
                    return "";
                }
                const edgeLength = this.topologyEdgeData[edgeId].length;
                return `${edgeLength.toFixed(0)} m`;
            });
        this.linkTextStyles(linkGroupText);

        // Final styling for data between nodes
        const lineSpacing = 10;
        linkGroup.each((d: any) => {
            const edgeId: string = "uuid_" + d.source.id + "_" + d.target.id;
            if (edgeId in this.topologyEdgeData) {
                // Line along edge to make selection easier
                // displayed as a purple line if it's NOT owned by Gigaclear
                const edgeOwner = this.topologyEdgeData[edgeId].owner;
                createD3RingLine(edgeId, "purple", 10).attr(
                    "opacity",
                    edgeOwner === "Gigaclear" || edgeOwner === "N/A" ? "0" : "0.1",
                );
                // Display Overhead Routes
                if (this.topologyEdgeData[edgeId].type === TrenchType.OVERHEAD) {
                    createD3RingLine(edgeId, "black", 10).attr("opacity", "0.2");
                    createD3RingLine(edgeId, "black", 10).attr("opacity", "0.2");
                }
            }
            if (
                !(edgeId in this.topologyEdgeData) ||
                !("cables" in this.topologyEdgeData[edgeId]) ||
                this.topologyEdgeData[edgeId].cables.length === 0
            ) {
                // If edge has no cables draw a dashed line
                createD3RingLine(edgeId, "#999999", 10).style("stroke", "#999999").style("stroke-dasharray", "10,10");
                return;
            }
            this.topologyEdgeData[edgeId].cables.forEach((cable, n) => {
                // TODO: this doesn't really work with straight lines as svg not a perfect circle.
                // multiple cables - might be better with arcs?
                // http://bl.ocks.org/thomasdobber/9b78824119136778052f64a967c070e0
                let sign;
                let offset;
                if (n === 0) {
                    sign = 0;
                } else if (n % 2 === 1) {
                    sign = +1;
                } else {
                    sign = -1;
                }
                offset = sign * lineSpacing;
                this.svgRingCableOffsets[edgeId + cable.name] = offset;
                createD3RingLine(edgeId, "", 10)
                    .attr("class", () => {
                        return "cable cable_f" + cable.size;
                    })
                    .attr("id", cable.name)
                    .on("click", () => {
                        if (this.previousTouchState === TouchState.NO_TOUCH) {
                            this.toggleCable(cable.name);
                        }
                    })
                    .on("touchend", () => this.toggleCable(cable.name))
                    .on("mousemove", () => (this.hoveredCable = cable.name));
            });
        });
        // linkGroup events

        // run simulation
        simulation.on("tick", () => {
            // positions elements during animation
            node.attr("transform", (d: any) => `translate(${d.x},${d.y})`);
            linkGroup
                .attr("x1", (d: any) => d.source.x)
                .attr("y1", (d: any) => d.source.y)
                .attr("x2", (d: any) => d.target.x)
                .attr("y2", (d: any) => d.target.y);
            linkGroup
                .selectAll("line")
                .attr("x1", (d: any) => d.source.x)
                .attr("y1", (d: any) => d.source.y)
                .attr("x2", (d: any) => d.target.x)
                .attr("y2", (d: any) => d.target.y);
            // cables
            // TODO: adjust offset cable calculation above, currently overlap
            linkGroup.each((d: any) => {
                const edgeId: string = "uuid_" + d.source.id + "_" + d.target.id;
                this.topologyEdgeData[edgeId].cables.forEach((cable, n) => {
                    const offset = this.svgRingCableOffsets[edgeId + cable.name];
                    d3.select("#" + edgeId)
                        .select("#" + cable.name)
                        .attr("x1", d.source.x + offset)
                        .attr("y1", d.source.y + offset)
                        .attr("x2", d.target.x + offset)
                        .attr("y2", d.target.y + offset);
                });
            });
            // label
            linkGroupText.attr(
                "transform",
                (d: any) => `translate(${(d.source.x + d.target.x) / 2},${(d.source.y + d.target.y) / 2})`,
            );
        });
    }

    calcAngleDegrees(x: any, y: any) {
        return (Math.atan2(y, x) * 180) / Math.PI;
    }

    resetZoomPan() {
        // TODO: For some reason pressing twice can result in different cases
        // When you're zoomed in and then press reset once, then again
        // This seems to be the "best" case as the design will always be in view
        this.zoomPan.zoom(0.2);
        try {
            const wrapper: any = document.getElementById("posWrapper")!.getBoundingClientRect()!;
            const svg: any = document.getElementById("tree")!.getBoundingClientRect()!;
            let x = 0;
            if (wrapper.width < svg.width) {
                x = (svg.width - wrapper.width) / 2;
            }
            this.zoomPan.pan({ x, y: 100 });
            this.zoomPan.center();
        } catch (err) {
            this.$apm.captureError(err);
        }
    }

    checkDiagramLost() {
        // TODO: Lock the diagram within this view
        const wrapper: any = document.getElementById("posWrapper");
        const svg: any = document.getElementById("tree")!.getBoundingClientRect();
        const wrapperDiagram: DOMRect = wrapper.getBoundingClientRect();

        const rightX = wrapperDiagram.x + wrapperDiagram.width;
        const bottomY = wrapperDiagram.y + wrapperDiagram.height;

        if (rightX < 0 || bottomY < 0 || wrapperDiagram.x > svg.width || wrapperDiagram.y > svg.height) {
            // Diagram is Lost
        }
    }

    nodeDataShow(d: d3.HierarchyNode<TreeNode>, event: MouseEvent | TouchEvent) {
        if (d.data.type === "Backhaul") {
            return;
        }

        let tooltipData = "No closures assigned";
        if (d.data.closures.length > 0) {
            const closuresSorted = d.data.closures.concat().sort((a, b) => a.name.localeCompare(b.name));
            const closureInfo = closuresSorted.map((c: ClosureInfo) => {
                let line = `Endpoint: ${d.data.name} | Closure: ${c.name} | Service Points: ${c.n_services}`;
                if (c.backhaul) {
                    line += ` | Backhaul: ${c.backhaul}`;
                }
                return line;
            });
            tooltipData = closureInfo.join("<br />");
        }
        this.displayTooltip(tooltipData, event);
    }

    cableDataShow(d: any, event: MouseEvent | TouchEvent) {
        const edgeId: string = "uuid_" + d.source.data.id + "_" + d.target.data.id;
        if (!(edgeId in this.topologyEdgeData)) {
            return;
        }
        const cables = this.topologyEdgeData[edgeId].cables;
        const cableInfo =
            cables.length === 0
                ? ["No cables assigned"]
                : cables.map(c => {
                      const cableInfoStyle =
                          c.name === this.hoveredCable
                              ? "margin:0;font-weight:bold; border: 1px solid black; padding: 0px 10px"
                              : "margin:0";
                      return `
                <p id="${c.name}_info" style="${cableInfoStyle}">
                    Cable: ${c.name} | Size: ${c.size} | Total Length: ${this.cableData[c.name].toFixed(0)} m
                </p>
            `;
                  });

        this.displayTooltip(cableInfo.join("\n"), event);
    }

    updateSVGTree(graph: Graph) {
        // Setup data
        const treeData = this.getTreeData(graph.nodes, graph.edges);
        const treeDepth = getArrayDepth(treeData);
        const treeWidth = getArrayBreadth(treeData);

        const width = treeWidth * 230;
        const height = treeDepth * 150;
        const treeLayout = d3.tree().size([width, height]);

        const d3TreeHierarchy = d3.hierarchy(treeData);
        treeLayout(d3TreeHierarchy);

        // Reset all the nodes/links and text (labels) from previous cabs
        d3.selectAll("g.node").remove();
        d3.selectAll("g.link").remove();
        d3.selectAll("g.links text").remove();

        // select container element
        const linksContainer: any = d3.select("svg .links");
        const nodesContainer: any = d3.select("svg .nodes");

        const linkGroup = linksContainer
            .selectAll("g.link")
            .data(d3TreeHierarchy.links())
            .enter()
            .append("g")
            .classed("link", true);

        const node = nodesContainer
            .selectAll("g.node")
            .data(d3TreeHierarchy.descendants())
            .enter()
            .append("g")
            .classed("node", true);

        node.append("circle")
            .attr("r", 25)
            .attr("cx", (d: any) => d.x)
            .attr("cy", (d: any) => d.y)
            .attr("class", (d: any) => {
                return this.nodeCircleTypeStyles(d.data.type, d.data.is_pia);
            })
            // Append 'uuid_' (as css selectors can't handle ones that start with a number)
            .attr("id", (d: d3.HierarchyNode<TreeNode>) => "uuid_" + d.data.id)
            .on("click", (event: MouseEvent, d: d3.HierarchyNode<TreeNode>) => {
                if (d.data.type !== "TrenchJunction" && d.data.type !== "Backhaul") {
                    this.toggleNode(d.data.id);
                }
            })
            .on("mousemove", (event: MouseEvent, d: d3.HierarchyNode<TreeNode>) => {
                this.nodeDataShow(d, event);
            })
            .on("mouseleave", () => {
                d3.select("#tooltip").style("opacity", "0");
            })
            .on("touchend", (event: TouchEvent, d: d3.HierarchyNode<TreeNode>) => {
                this.nodeDataShow(d, event);
            });
        // label for the node
        const label = node
            .append("text")
            .attr("dy", ".21em")
            .attr("transform", (d: any) => "translate(" + d.x + ", " + d.y + ")")
            .text((d: any) => (d.data.type === "AccessCabinet" ? d.data.name : ""));
        this.nodeLabelStyles(label);

        label
            .append("tspan")
            .classed("endpoint_name", true)
            .text((d: any) => {
                return d.data.type === "TrenchJunction" || d.data.type === "AccessCabinet" ? "" : d.data.name;
            })
            .attr("display", this.nodeLabelling === "endpoint_name" ? "inherit" : "none");

        label
            .append("tspan")
            .classed("closure_names", true)
            .text((d: any) => {
                const prefix = d.data.type === "AccessCabinet" ? " - " : "";
                if (d.data.closures.length !== 0) {
                    return (
                        prefix +
                        d.data.closures
                            .map((c: ClosureInfo) => c.name)
                            .sort((a: string, b: string) => a.localeCompare(b))
                            .join(", ")
                    );
                }
            })
            .attr("display", this.nodeLabelling === "closure_names" ? "inherit" : "none");

        label
            .append("tspan")
            .classed("service_point_count", true)
            .text((d: any) => {
                const prefix = d.data.type === "AccessCabinet" ? " - " : "";
                if (d.data.closures.length !== 0) {
                    const totalSP = d.data.closures
                        .map((c: ClosureInfo) => c.n_services)
                        .reduce((nServices: number, sum: number) => nServices + sum, 0);
                    return prefix + `${totalSP} SP`;
                }
            })
            .attr("display", this.nodeLabelling === "service_point_count" ? "inherit" : "none");

        // Visual data BETWEEN the nodes
        const defaultCableLength = 100;
        linkGroup
            .attr("id", (e: d3.HierarchyLink<TreeNode>) => {
                const sourceId = e.source.data.id;
                const targetId = e.target.data.id;
                return "uuid_" + sourceId + "_" + targetId;
            })
            .attr("transform", (d: any) => {
                const edgeSourceData = d.source;
                const edgeTargetData = d.target;
                const sourcex = edgeSourceData.x;
                const targetx = edgeTargetData.x;
                const dx = targetx - sourcex;
                const sourcey = edgeSourceData.y;
                const targety = edgeTargetData.y;
                const dy = targety - sourcey;
                const length = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
                return (
                    "translate(" +
                    sourcex +
                    " " +
                    sourcey +
                    ") " +
                    "rotate(" +
                    this.calcAngleDegrees(dx, dy) +
                    ") " +
                    "scale(" +
                    length / defaultCableLength +
                    " 1)"
                );
            });
        // Text displayed for the visual data between the nodes
        const linkGroupText = linksContainer
            .selectAll("text")
            .data(d3TreeHierarchy.links())
            .enter()
            .append("text")
            .attr("dy", ".31em")
            .attr("transform", (d: any) => {
                const edgeSourceData = d.source;
                const edgeTargetData = d.target;
                const sourcex = edgeSourceData.x;
                const targetx = edgeTargetData.x;
                const sourcey = edgeSourceData.y;
                const targety = edgeTargetData.y;
                return "translate(" + (sourcex + targetx) / 2 + ", " + (sourcey + targety) / 2 + ")";
            })
            .text((d: any) => {
                const edgeId: string = "uuid_" + d.source.data.id + "_" + d.target.data.id;
                const edgeData = this.topologyEdgeData[edgeId];
                if (edgeData === undefined) {
                    return "";
                }
                const edgeLength = this.topologyEdgeData[edgeId].length;
                return `${edgeLength.toFixed(0)} m`;
            });
        this.linkTextStyles(linkGroupText);
        // Final styling for data between nodes
        const lineSpacing = 10;
        linkGroup
            .each((d: any) => {
                const edgeId: string = "uuid_" + d.source.data.id + "_" + d.target.data.id;
                if (edgeId in this.topologyEdgeData) {
                    // Line along edge to make selection easier
                    // displayed as a purple line if it's NOT owned by Gigaclear
                    const edgeOwner = this.topologyEdgeData[edgeId].owner;
                    createD3Line(edgeId, 0, 0, defaultCableLength, 0, "purple", 50).attr(
                        "opacity",
                        edgeOwner === "Gigaclear" || edgeOwner === "N/A" ? "0" : "0.1",
                    );
                    // Display Overhead Routes
                    if (this.topologyEdgeData[edgeId].type === TrenchType.OVERHEAD) {
                        createD3Line(edgeId, 0, 20, defaultCableLength, 20, "black", 10).attr("opacity", "0.2");
                        createD3Line(edgeId, 0, -20, defaultCableLength, -20, "black", 10).attr("opacity", "0.2");
                    }
                }
                if (
                    !(edgeId in this.topologyEdgeData) ||
                    !("cables" in this.topologyEdgeData[edgeId]) ||
                    this.topologyEdgeData[edgeId].cables.length === 0
                ) {
                    // If edge has no cables draw a dashed line
                    createD3Line(edgeId, 0, 0, defaultCableLength, 0, "#999999", 6)
                        .style("stroke", "#999999")
                        .style("stroke-dasharray", "10,10");
                    return;
                }
                this.topologyEdgeData[edgeId].cables.forEach((cable, n) => {
                    let sign;
                    let offset;
                    if (n === 0) {
                        sign = 0;
                    } else if (n % 2 === 0) {
                        sign = +1;
                    } else {
                        sign = -1;
                    }
                    offset = Math.floor((n + 1) / 2) * sign * lineSpacing;
                    if (this.topologyEdgeData[edgeId].cables.length % 2 === 0) {
                        offset += lineSpacing / 2;
                    }
                    createD3Line(edgeId, 0, offset, defaultCableLength, offset, "", 6)
                        .attr("class", () => {
                            return "cable cable_f" + cable.size;
                        })
                        .attr("id", cable.name)
                        .on("click", () => {
                            if (this.previousTouchState === TouchState.NO_TOUCH) {
                                this.toggleCable(cable.name);
                            }
                        })
                        .on("touchend", () => this.toggleCable(cable.name))
                        .on("mousemove", () => (this.hoveredCable = cable.name));

                    const edgeSourceData = d.source;
                    const edgeTargetData = d.target;
                    const sourcex = edgeSourceData.x;
                    const targetx = edgeTargetData.x;
                    const dx = targetx - sourcex;
                    const sourcey = edgeSourceData.y;
                    const targety = edgeTargetData.y;
                    const dy = targety - sourcey;
                    const length = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
                    const scale = length / defaultCableLength;

                    if (cable.is_start) {
                        let x1 = 0;
                        let x2 = 50 / scale;

                        if (cable.direction === Direction._0) {
                            x1 = defaultCableLength - x1;
                            x2 = defaultCableLength - x2;
                        }
                        createD3Line(edgeId, x1, offset, x2, offset, "black", 6)
                            .attr("class", () => {
                                return "cable";
                            })
                            .attr("id", cable.name)
                            .on("click", () => {
                                if (this.previousTouchState === TouchState.NO_TOUCH) {
                                    this.toggleCable(cable.name);
                                }
                            })
                            .on("touchend", () => this.toggleCable(cable.name));
                    }

                    if (cable.is_end) {
                        let x1 = 0;
                        let x2 = 50 / scale;

                        if (cable.direction === Direction._0) {
                            x1 = defaultCableLength - x1;
                            x2 = defaultCableLength - x2;
                        }
                        createD3Line(
                            edgeId,
                            defaultCableLength - x1,
                            offset,
                            defaultCableLength - x2,
                            offset,
                            "black",
                            6,
                        )
                            .attr("class", () => {
                                return "cable";
                            })
                            .attr("id", cable.name)
                            .on("click", () => {
                                if (this.previousTouchState === TouchState.NO_TOUCH) {
                                    this.toggleCable(cable.name);
                                }
                            })
                            .on("touchend", () => this.toggleCable(cable.name));
                    }
                });
            })
            .on("mousemove", (event: MouseEvent, d: any) => {
                this.cableDataShow(d, event);
            })
            .on("mouseleave", () => {
                d3.select("#tooltip").style("opacity", "0");
                this.hoveredCable = "";
            })
            .on("touchend", (event: TouchEvent, d: any) => {
                // This is 'touched' when the cluster of cables is clicked
                this.cableDataShow(d, event);
            });

        linkGroup.exit().remove();
    }

    toggleLegend() {
        this.isLegendDisplayed = !this.isLegendDisplayed;
        const legendElement = document.getElementById("legend");
        if (!this.isLegendDisplayed) {
            legendElement!.style.transform = "translate(250px, 0)";
        } else if (this.isLegendDisplayed) {
            legendElement!.style.transform = "translate(0, 0)";
        }
    }

    toggleCable(cableName: string) {
        if (this.previousTouchState === TouchState.TOUCH_MOVE) {
            return;
        }
        if (cableName === this.cableSelected) {
            this.cableSelected = undefined;
            this.hoveredCable = "";
        } else {
            this.cableSelected = cableName;
            this.hoveredCable = cableName;
        }

        d3.selectAll("circle.node").classed("not_selected", false);
        const cableElement = document.getElementById("schematic_popup_info");
        if (this.cableSelected) {
            d3.selectAll("line.cable").classed("not_selected", true);

            const startId = this.cableDataByName[cableName].start;
            const endId = this.cableDataByName[cableName].end;

            d3.selectAll("#" + cableName).classed("not_selected", false);
            let cableData = `<h4>Selected Cable</h4>
                               <p>Cable Name: ${cableName}</p>
                               <p>Total Length: ${this.cableData[cableName].toFixed(2)} m</p>
                               <p>Size: ${this.cableDataByName[cableName].size}</p>`;
            cableData +=
                startId && endId ? `<p>From: ${this.nodeById[startId].label}, To: ${this.nodeById[endId].label}` : "";
            d3.select("#schematic_popup_info").html(cableData);
            cableElement!.style.transform = "translate(0, 0)";
        } else {
            d3.selectAll("line.cable").classed("not_selected", false);
            cableElement!.style.transform = "translate(-100%, 0)";
        }
    }

    toggleNode(nodeId: string) {
        if (nodeId === this.nodeSelected) {
            this.nodeSelected = undefined;
        } else {
            this.nodeSelected = nodeId;
        }
        const nodeElement = document.getElementById("schematic_popup_info");
        if (this.nodeSelected) {
            d3.selectAll("line.cable").classed("not_selected", true);
            d3.selectAll("circle.node").classed("not_selected", true);

            const nodeData = this.nodeById[nodeId];
            const sortedClosures = nodeData.closures
                .map((c: ClosureInfo) => c.name)
                .sort((a: string, b: string) => a.localeCompare(b))
                .join(", ");
            const sortedNodeCables = nodeData.cables.sort((a: string, b: string) => a.localeCompare(b)).join(", ");

            d3.selectAll("#uuid_" + nodeId).classed("not_selected", false);
            nodeData.cables.map((cable: string) => d3.selectAll("#" + cable).classed("not_selected", false));

            const nodeInfo = `<h4>Selected Node</h4>
                                <p>Endpoint Name: ${nodeData.label}</p>
                                <p>Closures: ${sortedClosures.length > 0 ? sortedClosures : "None"}</p>
                                <p>Cables: ${sortedNodeCables.length > 0 ? sortedNodeCables : "None"}</p>`;
            d3.select("#schematic_popup_info").html(nodeInfo);
            nodeElement!.style.transform = "translate(0, 0)";
        } else {
            d3.selectAll("line.cable").classed("not_selected", false);
            d3.selectAll("circle.node").classed("not_selected", false);
            nodeElement!.style.transform = "translate(-100%, 0)";
        }
    }

    resizeSVG() {
        const svg: any = document.getElementById("legend-svg");
        const bbox: SVGRect = svg!.getBBox();
        svg!.style.height = bbox.height + 100 + "px";
    }

    mounted() {
        this.$nextTick(() => {
            this.$watch("dataLoaded", (newValue: boolean) => {
                document.addEventListener("touchmove", () => (this.previousTouchState = TouchState.TOUCH_MOVE));
                document.addEventListener("touchstart", () => (this.previousTouchState = TouchState.TOUCH_START));
                const customHandle: SvgPanZoom.CustomEventHandler = {
                    haltEventListeners: ["touchstart", "touchend", "touchmove", "touchleave", "touchcancel"],
                    // Figure this out magically?
                    init: (customHandleOption: SvgPanZoom.CustomEventOptions) => {
                        const instance = customHandleOption.instance;

                        let pannedX = 0;
                        let pannedY = 0;
                        let initialScale = 1;

                        // Init Hammer
                        // Listen only for pointer and touch events
                        this.hammer = new Hammer(customHandleOption.svgElement);

                        // Enable pinch
                        this.hammer.get("pinch").set({ enable: true });

                        // Handle double tap
                        // TODO: Can we use this in a smart way for more actions?
                        // this.hammer.on('doubletap', (ev: any) => {
                        //     instance.zoomIn()
                        // })

                        // Handle pan
                        this.hammer.on("panstart panmove", (ev: any) => {
                            // On pan start reset panned variables
                            if (ev.type === "panstart") {
                                pannedX = 0;
                                pannedY = 0;
                            }

                            // Pan only the difference
                            instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY });
                            pannedX = ev.deltaX;
                            pannedY = ev.deltaY;
                        });

                        // Handle pinch
                        this.hammer.on("pinchstart pinchmove", (ev: any) => {
                            // On pinch start remember initial zoom
                            if (ev.type === "pinchstart") {
                                initialScale = instance.getZoom();
                                instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y });
                            }

                            instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y });
                        });

                        // Prevent moving the page on some devices when panning over SVG
                        customHandleOption.svgElement.addEventListener("touchmove", (e: any) => {
                            e.preventDefault();
                        });
                    },
                    destroy: () => {
                        this.hammer.destroy();
                    },
                };
                this.zoomPanOptions.customEventsHandler = customHandle;
                this.zoomPan = svgPanZoom("#tree", this.zoomPanOptions);
                window.addEventListener("resize", this.resetZoomPan);

                this.resetZoomPan();
                if (this.$route.params.designId && typeof this.$route.params.designId === "string") {
                    this.selectedCabinetId = this.$route.params.designId;
                }
                this.resizeSVG();
                if (this.cabinetTopology) {
                    this.onTopologyLoaded();
                }
            });
        });
    }

    render(h: CreateElement): JSX.Element {
        let content;
        if (this.errorOccurred) {
            content = <h2 style={{ margin: "20px" }}>Error fetching topology data: {this.errorMessage}</h2>;
        } else if (!this.cabinetTopology) {
            content = <h2 style={{ margin: "20px" }}>Loading...</h2>;
        } else {
            content = [
                <div class="topology-diagram ma-4">
                    <h2 style={{ margin: "20px" }}>Topology Diagram - {this.cabinetName}</h2>
                    <svg id="tree" viewBox="0 0 3500 800">
                        <g id="posWrapper">
                            <g class="links"></g>
                            <g class="nodes"></g>
                        </g>
                    </svg>
                    <div>
                        <v-layout>
                            <v-flex sm2>
                                <v-autocomplete
                                    v-model={this.selectedFeederNode}
                                    v-show={this.feederNodes && this.cabinetTopology}
                                    items={this.feederNodes}
                                    item-text="name"
                                    item-value="id"
                                    label="Feeder Nodes"
                                    onChange={this.onFeederNodeChange}
                                    onInput={this.onFeederNodeInput}
                                    clearable
                                />
                            </v-flex>
                            <v-radio-group v-model={this.nodeLabelling} row label="Node Labels:" class="pl-3">
                                <v-radio label="Closure Names" value="closure_names"></v-radio>
                                <v-radio label="Service Point Count" value="service_point_count"></v-radio>
                                <v-radio label="Endpoint Names" value="endpoint_name"></v-radio>
                            </v-radio-group>
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                            <div id="legend">
                                <svg id="legend-svg" width="100%">
                                    <g transform="translate(50, 30)">
                                        <text class="heading_text" x="25" y="8">
                                            Node Type
                                        </text>
                                        <circle cx="0" cy="50" r="15" class="access_cabinet_color" />
                                        <circle cx="0" cy="100" r="15" class="building_aggregation_point_color" />
                                        <circle cx="0" cy="150" r="15" class="chamber_color" />
                                        <circle cx="0" cy="200" r="15" class="drop_cabinet_color" />
                                        <circle cx="0" cy="250" r="15" class="gateway_cabinet_color" />
                                        <circle cx="0" cy="300" r="15" class="junction_color" />
                                        <circle cx="0" cy="350" r="15" class="backhaul_color" />
                                        <circle cx="0" cy="400" r="15" class="pole_color" />
                                        <circle cx="0" cy="450" r="15" class="pia_asset" style="fill:white" />
                                        <circle cx="0" cy="500" r="15" class="other_color" />
                                        <text class="access_cabinet_color" x="30" y="54">
                                            Access Cabinet
                                        </text>
                                        <text class="building_aggregation_point_color" x="30" y="104">
                                            Building Aggregation Point
                                        </text>
                                        <text class="chamber_color" x="30" y="154">
                                            Chamber
                                        </text>
                                        <text class="drop_cabinet_color" x="30" y="204">
                                            Drop Cabinet
                                        </text>
                                        <text class="gateway_cabinet_color" x="30" y="254">
                                            Gateway Cabinet
                                        </text>
                                        <text class="junction_color" x="30" y="304">
                                            Trench Junction
                                        </text>
                                        <text class="backhaul_color" x="30" y="354">
                                            Backhaul
                                        </text>
                                        <text class="pole_color" x="30" y="404">
                                            Pole
                                        </text>
                                        <text x="30" y="454">
                                            PIA Asset
                                        </text>
                                        <text class="other_color" x="30" y="504">
                                            Other
                                        </text>
                                        <text class="heading_text" x="25" y="554">
                                            Cable Type
                                        </text>
                                        {/* Cable Type */}
                                        <line x1="-10" y1="600" x2="40" y2="600" class="no_cable" />
                                        <line x1="-10" y1="640" x2="25" y2="640" class="start_point" />
                                        <line x1="25" y1="640" x2="40" y2="640" class="cable_f144" />
                                        <rect
                                            width="35"
                                            height="35"
                                            x="-5"
                                            y="670"
                                            style="fill:purple; opacity: 0.1;"
                                        />
                                        <line
                                            x1="0"
                                            y1="725"
                                            x2="0"
                                            y2="760"
                                            style="stroke: black; opacity: 0.2; stroke-width:5"
                                        />
                                        <line
                                            x1="25"
                                            y1="725"
                                            x2="25"
                                            y2="760"
                                            style="stroke: black; opacity: 0.2; stroke-width:5"
                                        />
                                        {/* Cable Size */}
                                        <line x1="-10" y1="840" x2="40" y2="840" class="cable_f288" />
                                        <line x1="-10" y1="880" x2="40" y2="880" class="cable_f144" />
                                        <line x1="-10" y1="920" x2="40" y2="920" class="cable_f96" />
                                        <line x1="-10" y1="960" x2="40" y2="960" class="cable_f72" />
                                        <line x1="-10" y1="1000" x2="40" y2="1000" class="cable_f48" />
                                        <line x1="-10" y1="1040" x2="40" y2="1040" class="cable_f36" />
                                        <line x1="-10" y1="1080" x2="40" y2="1080" class="cable_f24" />
                                        <line x1="-10" y1="1120" x2="40" y2="1120" class="cable_f12" />
                                        <line x1="-10" y1="1160" x2="40" y2="1160" class="cable_f8" />
                                        <line x1="-10" y1="1200" x2="40" y2="1200" class="cable_f4" />
                                        <text fill="black" x="70" y="604">
                                            No Cable
                                        </text>
                                        <text fill="black" x="70" y="644">
                                            Termination
                                        </text>
                                        <text fill="black" x="70" y="690">
                                            PIA Route
                                        </text>
                                        <text fill="black" y="740">
                                            <tspan x="70">Overhead</tspan>
                                            <tspan x="70" dy="20">
                                                Route
                                            </tspan>
                                        </text>
                                        <text class="heading_text" x="25" y="794">
                                            Cable Size
                                        </text>
                                        <text fill="rgb(187, 72, 240)" x="70" y="844">
                                            288{" "}
                                        </text>
                                        <text fill="rgb(255, 87, 87)" x="70" y="884">
                                            144{" "}
                                        </text>
                                        <text fill="rgb(238, 112, 39)" x="70" y="924">
                                            96
                                        </text>
                                        <text fill="rgb(191, 209, 71)" x="70" y="964">
                                            72
                                        </text>
                                        <text fill="rgb(77, 226, 134)" x="70" y="1004">
                                            48
                                        </text>
                                        <text fill="rgb(79, 213, 186)" x="70" y="1044">
                                            36
                                        </text>
                                        <text fill="rgb(81, 199, 238)" x="70" y="1084">
                                            24
                                        </text>
                                        <text fill="rgb(82, 108, 240)" x="70" y="1124">
                                            12
                                        </text>
                                        <text fill="rgb(57, 80, 172)" x="70" y="1164">
                                            8
                                        </text>
                                        <text fill="rgb(46, 63, 137)" x="70" y="1204">
                                            4
                                        </text>
                                    </g>
                                </svg>
                            </div>
                            <v-btn
                                text
                                color="primary"
                                onClick={() => this.toggleLegend()}
                                class="mt-3"
                                style={{ fontSize: "20px", padding: "12px 24px" }}
                            >
                                Legend
                                <v-icon right>mdi-map-legend</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                onClick={this.resetZoomPan}
                                class="mt-3"
                                style={{ fontSize: "20px", padding: "12px 24px" }}
                            >
                                Reset<v-icon right>mdi-refresh</v-icon>
                            </v-btn>
                        </v-layout>
                    </div>
                    <div id="tooltip">Mouse-tracking HTML Tip</div>
                    <div id="schematic_popup_info"></div>
                </div>,
            ];
        }
        return <v-container>{content}</v-container>;
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-33e2e56c/topology-diagram.tsx" });