import * as tsx from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { VContainer, VCard, VCardTitle, VCardText, VDivider } from "vuetify/lib";

interface Pole {
    asset_id: string;
    asset_name: string;
    asset_type: string;
    cabinet_area_id: number;
    permanent_id: string;
    is_inspectable: boolean;
}
interface PoleInspections {
    asset_permanent_id: string;
    entered_by: string;
    entered_on: string;
    id: number;
    inspection_date: string;
}
interface PoleProperties {
    asset_permanent_id: string;
    entered_by: string;
    entered_on: string;
    id: number;
    date_installed: string;
}
export interface PoleData {
    pole: Pole;
    pole_inspections: PoleInspections[];
    pole_properties: PoleProperties[];
}

@Component({
    components: {
        VContainer,
        VCard,
        VCardTitle,
        VCardText,
        VDivider,
    },
})
export default class PoleInfoFromAssetManagement extends tsx.Component<{ poleData: PoleData }> {
    @Prop({ required: true }) poleData!: PoleData;

    private generatePoleDetails(pole: Pole): JSX.Element {
        return (
            <div>
                <p>
                    <strong>Asset ID:</strong> {pole.asset_id}
                </p>
                <p>
                    <strong>Asset Name:</strong> {pole.asset_name}
                </p>
                <p>
                    <strong>Inspectable:</strong> {pole.is_inspectable ? "Yes" : "No"}
                </p>
            </div>
        );
    }

    private generateInspections(inspections: PoleInspections[]): JSX.Element {
        if (!inspections.length) {
            return <p>No inspections available.</p>;
        }
        return (
            <ul>
                {inspections.map(inspection => (
                    <li key={inspection.id}>
                        <strong>Inspection date:</strong> {inspection.inspection_date} <strong>Entered by:</strong>{" "}
                        {inspection.entered_by}
                    </li>
                ))}
            </ul>
        );
    }

    private generateProperties(properties: PoleProperties[]): JSX.Element {
        if (!properties.length) {
            return <p>No properties available.</p>;
        }
        return (
            <ul>
                {properties.map(property => (
                    <li key={property.id}>
                        <strong>Date Installed:</strong> {property.date_installed} <strong>Entered by:</strong>{" "}
                        {property.entered_by}
                    </li>
                ))}
            </ul>
        );
    }

    render(): JSX.Element {
        const { pole, pole_inspections, pole_properties } = this.poleData;

        return (
            <v-container>
                <v-card>
                    <v-card-title>
                        <h2>Pole Information</h2>
                    </v-card-title>
                    <VDivider />
                    <v-card-text>
                        {this.generatePoleDetails(pole)}
                        <h3>Inspections</h3>
                        {this.generateInspections(pole_inspections)}
                        <h3>Properties</h3>
                        {this.generateProperties(pole_properties)}
                    </v-card-text>
                </v-card>
            </v-container>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-fdb5f3cc/pole-info-from-asset-management.tsx" });