import { gigamapApiFactory } from "@/services";
import * as tsx from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { VContainer, VBtn } from "vuetify/lib";
import { CreateElement } from "vue";
import { accessTokenFromStore, showSnackbar } from "@/utils";

@Component({
    components: {
        VContainer,
        VBtn,
    },
})
export class FileViewer extends tsx.Component<{}> {
    attachmentId: string = "";
    fileContent: string = "";
    fileType: string = "";
    fileName: string = "";
    loading: boolean = true;
    error: string = "";
    blob: Blob | null = null;

    async mounted() {
        try {
            this.attachmentId = this.$route.params.attachmentId;
            const token = accessTokenFromStore(this.$store) ?? "";
            const gigamapClient = gigamapApiFactory(token);
            const response = await gigamapClient.getFile(this.attachmentId);

            if (!response || !response.ok) {
                throw new Error(`Failed to fetch file: ${response.status}`);
            }

            this.fileType = response.headers.get("content-type") || "";
            this.fileName = this.getFileName(response.headers.get("content-disposition"));
            this.blob = await response.blob();

            if (this.fileType.includes("image") || this.fileType.includes("pdf")) {
                this.fileContent = URL.createObjectURL(this.blob);
            }
        } catch (error) {
            console.error("Error loading file:", error);
            this.error = error instanceof Error ? error.message : "Failed to load file";
        } finally {
            this.loading = false;
        }
    }

    getFileName(contentDisposition: string | null): string {
        if (!contentDisposition) return this.attachmentId;
        // Example input: attachment; filename="file.pdf"
        // Extract filename from content disposition header using regex
        // Captures all characters between filename=" and " except quotes
        const matches = /filename="([^"]*)"/.exec(contentDisposition);
        console.log(matches);

        // matches[0] contains full match, matches[1] contains filename
        if (matches && matches[1]) {
            return matches[1];
        }
        return this.attachmentId;
    }

    downloadFile() {
        if (!this.blob) {
            showSnackbar("Failed to load file");
            return;
        }
        const downloadUrl = URL.createObjectURL(this.blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = this.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
    }

    beforeDestroy() {
        if (this.fileContent) {
            URL.revokeObjectURL(this.fileContent);
        }
    }

    render(h: CreateElement): JSX.Element {
        let content;
        if (this.loading) {
            content = <div>Loading...</div>;
        } else if (this.error) {
            content = <div class="error">{this.error}</div>;
        } else if (this.fileType.includes("image")) {
            content = <img src={this.fileContent} alt="File preview" style="max-width: 100%" />;
        } else if (this.fileType.includes("pdf")) {
            content = <iframe src={this.fileContent} style="width: 100%; height: 100vh; border: none;"></iframe>;
        } else if (this.blob) {
            content = (
                <div class="text-center p-6">
                    <h3 class="text-xl mb-4">Preview Not Available</h3>
                    <p class="mb-2">
                        You can't preview <b>{this.fileName}</b> at this time.
                    </p>
                    <p class="mb-6">Please download the file to view its contents.</p>
                    <v-btn color="primary" onClick={() => this.downloadFile()}>
                        Download
                    </v-btn>
                </div>
            );
        }

        return <v-container>{content}</v-container>;
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-2f1c4d44/file-viewer.tsx" });