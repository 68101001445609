import { CreateElement } from "vue";
import { Component, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { State } from "vuex-class";
import SvgIconLoad from "@/components/svg-file-icon";
import {
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VBtn,
    VIcon,
    VTooltip,
} from "vuetify/lib";
// @ts-ignore
import { getTileBBox } from "@mapbox/whoots-js";

import { FeatureInfo } from "@/services/gigamap";
import { QueryResults } from "@/views/map";
import * as store from "@/store";
import GigastoreFileUpload from "./gigastore-file-upload";
import { gigamapApiFactory, messages } from "@/services";
import { apiUrl } from "@/appconfig";
import { cacheRequests, showLoadingDialog, showSnackbar, COMMUNITY_DOWNLOAD_STATUS } from "@/utils";
import { GeoJSONGeometry } from "wellknown";
import GigastoreFileDelete from "./gigastore_file_delete";
interface StringMap {
    [key: string]: string;
}

interface QueryPopupProps {
    results: QueryResults;
    geometry?: GeoJSONGeometry;
    designId?: string;
    cabinetName?: string;
    enableEdit?: boolean;
}

const WMS_PARAMS = [
    "request=GetMap",
    "crs=EPSG:3857",
    "format=image/png8",
    "tiled=true",
    "width=256",
    "height=256",
    "version=1.3.0",
    "layers=os:OS+Background+Maps",
    "transparent=false",
    "bbox={bbox-epsg-3857}",
];

interface LayerInfo {
    itemLabels: StringMap;
    editable: boolean;
    source?: string;
}

export async function precacheDesign(token: string, community: string) {
    const client = gigamapApiFactory(token);
    const API_URL = apiUrl();
    const WMS_URL = `${API_URL}/wms?${WMS_PARAMS.join("&")}`;
    const mapCache = await caches.open(community);
    const reqOptions = { headers: { Authorization: `Bearer ${token}` } };
    const requests = [];
    const tiles = await client.getDesignTiles(community);

    for (const [z, x, y] of tiles) {
        const tilePath = `/${z}/${x}/${y}`;
        // Vector tiles can be overzoomed - so don't request higher zoom levels
        if (z <= 17) {
            requests.push(new Request(`${API_URL}${tilePath}.pbf`, reqOptions));
        }
        requests.push(new Request(WMS_URL.replace("{bbox-epsg-3857}", getTileBBox(x, y, z)), reqOptions));
    }
    const success = await cacheRequests(mapCache, requests);
    const communityDownloadStatus = localStorage.getItem(COMMUNITY_DOWNLOAD_STATUS);
    let communityDownloadStatusList: { [key: string]: string } = {};

    if (communityDownloadStatus != null) {
        communityDownloadStatusList = JSON.parse(communityDownloadStatus);
    }

    if (success) {
        showSnackbar(`Finished fetching tiles for ${community}`);
        communityDownloadStatusList[community] = "Complete";
    } else {
        showSnackbar(`Couldn't fetch tiles for ${community}. Check your connection.`);
        communityDownloadStatusList[community] = "Download didn't complete successfully.";
    }

    localStorage.setItem(COMMUNITY_DOWNLOAD_STATUS, JSON.stringify(communityDownloadStatusList));
}

function getLayerInfo(layers: store.Layers): { [key: string]: LayerInfo } {
    return Object.fromEntries(
        Object.values(layers).map(lyr => {
            return [
                lyr.label,
                {
                    itemLabels: Object.fromEntries(lyr.properties.map(prop => [prop.key, prop.description])),
                    editable: lyr.properties.some(prop => prop.editable),
                    source: lyr.source,
                },
            ];
        }),
    );
}

@Component({
    components: {
        VList,
        VListItem,
        VListItemAction,
        VListItemTitle,
        VListItemContent,
        VBtn,
        VIcon,
        VTooltip,
    },
})
export default class QueryPopup extends tsx.Component<QueryPopupProps> {
    @Prop() results?: QueryResults;
    @Prop() designId?: string;
    @Prop() cabinetName?: string;
    @Prop() geometry?: GeoJSONGeometry;
    @Prop() enableEdit?: boolean;

    @State((s: store.State) => s.layers) layers: store.Layers;
    @State((s: store.State) => s.cablePathVisible) cablePathVisible: boolean;
    currentFeature?: FeatureInfo;
    showGigastoreDialog: boolean;
    showGigastoreDeleteDialog: boolean;

    data() {
        return {
            currentFeature: null,
            showGigastoreDialog: false,
            showGigastoreDeleteDialog: false,
        };
    }

    uploadFileWithoutAssetAction() {
        return (
            <v-btn
                class="mt-2"
                color="primary"
                onClick={() => {
                    this.showGigastoreDialog = true;
                    this.currentFeature = undefined;
                }}
            >
                Add to Gigastore
                <v-icon medium>mdi-file-plus</v-icon>
            </v-btn>
        );
    }

    render(h: CreateElement): JSX.Element | undefined {
        return (
            <div>
                {this.cablePathVisible && this.hideCablePath()}
                {this.results && (
                    <v-list dense class="pa-2">
                        {this.renderQueryResults(this.results)}
                    </v-list>
                )}
                {this.designId && (
                    <GigastoreFileUpload
                        v-model={this.showGigastoreDialog}
                        designId={this.designId}
                        geometry={this.currentFeature?.geometry || this.geometry}
                        assetId={this.currentFeature?.id}
                    ></GigastoreFileUpload>
                )}
                {this.designId && (
                    <GigastoreFileDelete
                        v-model={this.showGigastoreDeleteDialog}
                        designId={this.designId}
                        file_data={this.currentFeature?.properties}
                    ></GigastoreFileDelete>
                )}
            </div>
        );
    }

    hideCablePath() {
        return (
            <div class="d-flex justify-center">
                <v-list dense class="pa-2">
                    <v-list-item class="pl-0 pr-0">
                        <v-tooltip
                            bottom
                            open-delay={400}
                            scopedSlots={{
                                activator: ({ on }: any) => {
                                    return (
                                        <v-btn
                                            icon
                                            color="primary"
                                            onClick={() => messages.$emit("hide-cable-path")}
                                            {...{ on }}
                                        >
                                            Hide Cable Path
                                            <v-icon medium>mdi-lightning-bolt</v-icon>
                                        </v-btn>
                                    );
                                },
                            }}
                        >
                            <span>Hide Cable Path</span>
                        </v-tooltip>
                    </v-list-item>
                </v-list>
            </div>
        );
    }

    renderQueryResults(results: QueryResults): JSX.Element[] {
        const items = [];
        const layerInfo = getLayerInfo(this.layers);
        for (const layerName in results) {
            if (results.hasOwnProperty(layerName)) {
                const features = results[layerName];
                items.push(<v-list-item-title>{layerName}</v-list-item-title>);
                for (const feature of features) {
                    items.push(this.renderFeatureInfo(feature, layerInfo[layerName], layerName));
                }
            }
        }
        return items;
    }

    accessTokenFromStore() {
        return this.$store.state.accessToken;
    }

    renderFeatureInfo(feature: FeatureInfo, layerInfo: LayerInfo, layerName: string): JSX.Element {
        const self = this;
        async function downloadDesign(community: string) {
            const dialog = showLoadingDialog(`Downloading ${community}...`);
            await precacheDesign(self.accessTokenFromStore(), community);
            dialog.close();
        }
        const uploadFileAction = (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                icon
                                color="primary"
                                onClick={() => {
                                    this.showGigastoreDialog = true;
                                    this.currentFeature = feature;
                                }}
                                {...{ on }}
                            >
                                <v-icon medium>mdi-file-plus</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>Add file to Gigastore</span>
            </v-tooltip>
        );
        const deleteFileAction = (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                icon
                                color="primary"
                                onClick={() => {
                                    this.showGigastoreDeleteDialog = true;
                                    this.currentFeature = feature;
                                }}
                                {...{ on }}
                            >
                                <v-icon medium>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>Delete file from Gigastore</span>
            </v-tooltip>
        );
        const communityName = feature.properties.find(prop => prop.key === "name")?.value ?? "";
        const downloadDesignAction = (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn icon color="primary" onClick={() => downloadDesign(communityName)} {...{ on }}>
                                <v-icon medium>mdi-cellphone-arrow-down</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>Download Community</span>
            </v-tooltip>
        );
        const handleSplicingInformation = (designId: string, closureId: string) => {
            const closureName = feature.properties.find(prop => prop.key === "name")?.value ?? "";
            if (this.cabinetName) {
                const splicingNames = {
                    cabinetName: this.cabinetName,
                    closureName: closureName,
                };
                localStorage.setItem(closureId, JSON.stringify(splicingNames));
            }
            window.open(`/closure-splicing-diagram/designId=${designId}/closureId=${closureId}`);
        };
        const downloadSplicingInformation = (designId: string, closureId: string) => {
            return (
                <v-tooltip
                    bottom
                    open-delay={400}
                    scopedSlots={{
                        activator: ({ on }: any) => {
                            return (
                                <v-btn
                                    icon
                                    color="primary"
                                    onClick={() => handleSplicingInformation(designId, closureId)}
                                    {...{ on }}
                                >
                                    <SvgIconLoad
                                        src="/splicing_diagram_button.svg"
                                        color={this.$vuetify.theme.themes.light.primary}
                                    />
                                </v-btn>
                            );
                        },
                    }}
                >
                    <span>Open Splicing Information</span>
                </v-tooltip>
            );
        };
        const editDesignAction = (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                icon
                                color="primary"
                                onClick={() => messages.$emit("edit-feature", layerInfo.source, feature)}
                                {...{ on }}
                            >
                                <v-icon medium>mdi-pencil</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>Edit Attributes</span>
            </v-tooltip>
        );
        const showCablePath = (designId: string, servicePointId: string) => (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                icon
                                color="primary"
                                onClick={() => messages.$emit("show-cable-path", designId, servicePointId)}
                                {...{ on }}
                            >
                                <v-icon medium>mdi-lightning-bolt-circle</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>Show Cable Path</span>
            </v-tooltip>
        );
        const openSLDAction = (designId: string) => (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                icon
                                color="primary"
                                onClick={() => messages.$emit("open-sld", designId, feature)}
                                {...{ on }}
                            >
                                <v-icon medium>mdi-chart-sankey-variant</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>Open Straight Line Diagram</span>
            </v-tooltip>
        );
        const openTopologyDiagram = (designId: string) => (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                icon
                                color="primary"
                                onClick={() => messages.$emit("open-topology-diagram", designId)}
                                {...{ on }}
                            >
                                <v-icon medium>mdi-family-tree</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>Open Topology Diagram</span>
            </v-tooltip>
        );
        const openSplitPanelAction = (
            <v-tooltip
                bottom
                open-delay={400}
                scopedSlots={{
                    activator: ({ on }: any) => {
                        return (
                            <v-btn
                                icon
                                color="primary"
                                onClick={() => {
                                    if (layerName === "Poles") {
                                        messages.$emit("get-pole-properties", feature.id);
                                    } else if (layerName === "Wayleaves" || layerName === "Logged NOIs") {
                                        messages.$emit("open-split-panel", feature);
                                    }
                                }}
                                {...{ on }}
                            >
                                <v-icon medium>mdi-view-split-vertical</v-icon>
                            </v-btn>
                        );
                    },
                }}
            >
                <span>{layerName === "Poles" ? "Display Pole properties" : "Open Edit Panel"}</span>
            </v-tooltip>
        );

        const featureActions: JSX.Element[] = layerInfo.editable && this.enableEdit ? [editDesignAction] : [];

        if (layerName === "Communities") {
            featureActions.push(downloadDesignAction);
        } else if (layerName == "Access Cabinets" && this.designId) {
            featureActions.push(openSLDAction(this.designId));
            featureActions.push(openTopologyDiagram(this.designId));
        } else if (layerName === "Closures" && this.designId && feature.id) {
            featureActions.push(downloadSplicingInformation(this.designId, feature.id));
        } else if (layerName === "Services" && this.designId && feature.id) {
            featureActions.push(showCablePath(this.designId, feature.id));
        } else if (layerName === "Poles" && feature.id) {
            featureActions.push(openSplitPanelAction);
        } else if (layerName === "Wayleaves" && this.designId) {
            featureActions.push(openSplitPanelAction);
        } else if (layerName == "File Records" && this.designId) {
            featureActions.push(deleteFileAction);
        } else if (layerName == "Logged NOIs") {
            featureActions.push(openSplitPanelAction);
        }

        if (layerName !== "File Records" && layerName !== "Logged NOIs" && this.designId) {
            featureActions.push(uploadFileAction);
        }

        if (layerName == "Logged NOIs") {
            delete layerInfo.itemLabels.uuid;
        }

        return (
            <v-list-item class="pl-0 pr-0">
                <v-list-item-content>
                    {feature.properties
                        .filter(prop => !!prop.value && prop.key in layerInfo.itemLabels)
                        .map(prop => {
                            let description = layerInfo.itemLabels[prop.key];
                            let value = prop.value;
                            if (layerName === "File Records" && description === "Attachment ID") {
                                description = "Download";
                                const file_url = `/attachment-view/attachment_id=${prop.value}`;
                                value = (
                                    <a href={file_url} target="_blank">
                                        Download
                                    </a>
                                );
                            } else if (typeof prop.value === "object" && "url" in prop.value) {
                                value = (
                                    <a href={prop.value.url} target="_blank">
                                        {prop.value.text}
                                    </a>
                                );
                            } else if (typeof prop.value === "string" && prop.value.startsWith("http")) {
                                value = (
                                    <a href={prop.value} target="_blank">
                                        {prop.value}
                                    </a>
                                );
                            } else if (layerName === "Communities" && description === "Friendly Name") {
                                value = prop.value.split(",").join(" & ");
                            } else if (layerName === "File Records" && description === "Uploaded On") {
                                value = new Date(prop.value).toLocaleDateString("en-UK");
                            } else if (prop.key === "asset_data") {
                                const items = [];
                                const asset_data_obj: { [key: string]: string } = JSON.parse(prop.value);
                                for (let [key, value] of Object.entries(asset_data_obj)) {
                                    items.push(
                                        <div>
                                            <b>{key}</b>: {value}
                                        </div>,
                                    );
                                }
                                value = items;
                            }
                            return (
                                <div>
                                    <b>{description}</b>: {value}
                                </div>
                            );
                        })}
                </v-list-item-content>
                {featureActions.length > 0 && <v-list-item-action>{featureActions}</v-list-item-action>}
            </v-list-item>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-5e3841b0/query-popup.tsx" });