import "@material/mwc-snackbar";
import "@material/mwc-dialog";
import "@material/mwc-linear-progress";

import type { Dialog } from "@material/mwc-dialog";
import proj4 from "proj4";
import { Store } from "vuex";
import { State } from "./store";
import { TokenPayload } from "./services";
import jwt_decode from "jwt-decode";

export const MAP_CACHE = "map-cache";
export const COMMUNITY_DOWNLOAD_STATUS = "community_download_status";
let unsavedChanges = false;

function* chunked<T>(array: T[], size: number = 2): Iterable<T[]> {
    let start = 0;
    let stop = size;
    while (stop < array.length) {
        yield array.slice(start, stop);
        start += size;
        stop += size;
    }
    // Return any remaining items
    if (start < array.length) {
        yield array.slice(start, stop);
    }
}

export function accessTokenFromStore(store: Store<State>): string | undefined {
    let token = store.state.accessToken;
    return token;
}

export function isExternalUser(token: string): boolean {
    const payload: TokenPayload = jwt_decode(token);
    // TODO - Should this be configurable
    return !(payload.roles.includes("GCStaff") || payload.roles.includes("roleGigamapPublisher_GigamapEdit"));
}

export async function cacheRequests(cache: Cache, requests: Request[]) {
    for (const reqGroup of chunked(requests, 4)) {
        try {
            const responses = await Promise.all(reqGroup.map(r => fetch(r)));
            for (const resp of responses) {
                if (resp.status === 200) {
                    await cache.put(resp.url, resp);
                }
            }
        } catch (e) {
            return false;
        }
    }
    return true;
}

/**
 * Helper function for displaying a snackbar with the following notification
 *
 * @param text
 */
export function showSnackbar(text: string) {
    const body = document.querySelector("body")!;

    let snackbar = document.querySelector("mwc-snackbar");
    if (!snackbar) {
        snackbar = document.createElement("mwc-snackbar");
        body.append(snackbar);
    }
    snackbar.labelText = text;
    snackbar.open = true;
}

export function showLoadingDialog(heading: string): Dialog {
    const body = document.querySelector("body");
    let dialog = document.querySelector("mwc-dialog#loading-dialog") as Dialog;
    if (!dialog) {
        dialog = document.createElement("mwc-dialog");
        dialog.id = "loading-dialog";
        dialog.scrimClickAction = "";
        dialog.escapeKeyAction = "";
        dialog.hideActions = true;
        const progress = document.createElement("mwc-linear-progress");
        progress.indeterminate = true;
        dialog.append(progress);
        body?.append(dialog);
    }
    dialog.heading = heading;
    dialog.open = true;
    return dialog;
}

/**
 * Project an array of coordinates from WGS84 to EPSG:27700
 */
export function projectToOSGB36(coordinates: number[]): number[] {
    const bNGProjection =
        "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs";
    return proj4(bNGProjection).forward(coordinates);
}

export function setUnsavedChanges(value: boolean) {
    unsavedChanges = value;
}

export function hasUnsavedChanges(): boolean {
    return unsavedChanges;
}

export function isMobileDisplay(): boolean {
    return window.matchMedia("(max-width: 600px)").matches;
}
