import { getAuthToken as getAuthV1Token, TokenPayload as TokenAuthV1Payload } from "@/services";
import { getAzureAccessToken } from "./oauth";
import { getAzureUserData as AuthV2UserData, UserIdPayload as UserAuthV2Payload } from "@/oauth";
import { showSnackbar } from "@/utils";
import { gigamapApiFactory } from "@/services";

export interface AuthConfigs {
    authVersion?: string;
    authServiceUrl?: string;
    applicationId?: string;
    tenantId?: string;
    authority?: string;
    redirectUri?: string;
    loginScopes?: string[];
    tokenScopes?: string[];
}

// Get configs from an unauthenticated API endpoint
export async function getAuthConfigs(url: string) {
    const response = await fetch(url, { cache: "no-cache" });
    if (!response.ok || response.status !== 200) {
        throw new Error(`Failed to fetch auth configs: ${response.statusText}`);
    }
    try {
        const configs = await response.json();
        const authConfigs: AuthConfigs = {
            authVersion: configs.auth_version,
            applicationId: configs.application_id || "",
            tenantId: configs.tenant_id || "",
            authority: configs.authority,
            redirectUri: configs.redirect_uri,
            loginScopes: configs.login_scopes,
            tokenScopes: configs.access_token_scopes,
        };
        return authConfigs;
    } catch (error) {
        throw new Error(`Failed to parse auth configs as JSON: ${error}`);
    }
}

export async function getHealthCheck(url: string, accessToken: string) {
    try {
        const healthCheck = await fetch(url, {
            headers: { Authorization: "Bearer " + accessToken },
        });
        if (healthCheck.status >= 200 && healthCheck.status < 300) {
            const response = await healthCheck.json();
            return response;
        }
    } catch (error) {
        return error.message;
    }
}

export async function getAuthorizationToken(): Promise<string | undefined> {
    // First, attempt to get a token from Azure
    // If it doesn't exist, try to get a token from auth-service
    // Return undefined if no token is found in either source
    let apiToken = await getAzureAccessToken();
    if (apiToken !== undefined) {
        return apiToken;
    }
    const [jwt] = getAuthV1Token();
    apiToken = jwt;
    return apiToken;
}

export async function isUsingAzureAuth(): Promise<boolean> {
    let apiToken = await getAzureAccessToken();
    return apiToken !== undefined;
}

export async function getUserFromAnyAuth(): Promise<UserAuthV2Payload | TokenAuthV1Payload | undefined> {
    // First, attempt to get user data from Azure
    // If unsuccessful, try to retrieve it from the auth service
    let userData = null;
    userData = AuthV2UserData();

    if (userData === null) {
        const [, payload] = getAuthV1Token();
        userData = payload;
    }
    return userData;
}

export async function getAssetDbToken(originalToken: string): Promise<string> {
    // On Azure auth2, we need to generate a new obo token for asset-db
    // We are using gigamap-api to generate obo token for asset-db

    try {
        const gigamap_client = gigamapApiFactory(originalToken);
        const response = await gigamap_client.generateUserTokenForAssetDb();

        switch (response.status) {
            case 200: {
                const tokenText = await response.text();
                return tokenText.replace(/"/g, "").trim();
            }
            default: {
                throw new Error(`Failed to get asset-db token: ${response.status}`);
            }
        }
    } catch (error) {
        showSnackbar("Unauthorized to access asset-db");
        throw error;
    }
}
