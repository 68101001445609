import { AccountInfo, PublicClientApplication, SilentRequest } from "@azure/msal-browser";

// Instance of the Microsoft Authentication Library (MSAL) used for OAuth2.0
let msalInstance: PublicClientApplication;
let defaultLoginScopes: string[];
let defaultAccessTokenScopes: string[];

export interface UserIdPayload {
    uid: string;
    name: string;
    email?: string;
    exp?: number;
    roles?: string[];
}

const AUTH_KEY = "gcmsal";

export default function initAuth(msalConfig?: any, loginScopes?: string[], tokenScopes?: string[]) {
    if (msalInstance) {
        return null;
    }
    if (!msalConfig) {
        throw Error("Initialising OAuth client requires config object");
    }
    msalInstance = new PublicClientApplication(msalConfig);
    defaultLoginScopes = loginScopes || [];
    defaultAccessTokenScopes = tokenScopes || [];
}

export async function login(scopes?: string[]): Promise<UserIdPayload | string | null> {
    if (!msalInstance) {
        return null;
    }
    const loginRequest = {
        scopes: scopes && scopes.length > 0 ? scopes : defaultLoginScopes,
    };
    const response = await msalInstance
        .loginPopup(loginRequest)
        .then(loginResponse => {
            const myAccounts = msalInstance.getAllAccounts();
            return formatUserData(myAccounts[0]);
        })
        .catch(error => {
            if (error.message.includes("user_cancelled")) {
                return "Login Error: User cancelled the login flow.";
            }
            return null;
        });
    return response;
}

export async function logout() {
    if (!msalInstance) {
        return null;
    }
    await msalInstance.logoutPopup();
}

function formatUserData(account: AccountInfo | null): UserIdPayload | null {
    function getUserIdFromEmail(email: string) {
        let uid = "";
        if (email) {
            uid = email.split("@")[0];
        }
        return uid;
    }
    if (account && account.idTokenClaims) {
        const idClaims: any = account.idTokenClaims;
        const userInfo: UserIdPayload = {
            uid: account.username ? getUserIdFromEmail(account.username) : "",
            name: account.name || "",
            email: account.username || "",
            exp: idClaims.exp || 300000,
            roles: idClaims.roles || [],
        };
        return userInfo;
    } else {
        return null;
    }
}

export function getAccount(): AccountInfo | null {
    if (!msalInstance) {
        return null;
    }
    const myAccounts = msalInstance.getAllAccounts();
    if (!myAccounts || myAccounts.length === 0) {
        // No user signed in
        return null;
    } else {
        // If we have multiple accounts, we choose the first with idTokenClaims defined params
        for (const account of myAccounts) {
            if (account.idTokenClaims) {
                return account;
            }
        }
        return null;
    }
}

export async function acquireTokens(tokenScopes: string[]) {
    if (!msalInstance) {
        return null;
    }
    const tokenRequest: SilentRequest = {
        scopes: tokenScopes,
    };
    const account = getAccount();
    if (account) {
        tokenRequest.account = account;
    }

    try {
        const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
        return tokenResponse;
    } catch (error) {
        // Use popup in case of acquireTokenSilent failure due to need for consent or interaction
        if (
            error.errorCode === "consent_required" ||
            error.errorCode === "interaction_required" ||
            error.errorCode === "login_required"
        ) {
            try {
                const tokenResponse = await msalInstance.acquireTokenPopup(tokenRequest);
                return tokenResponse;
            } catch (error) {
                return null;
            }
        }
        return null;
    }
}

export function getAzureUserData(): UserIdPayload | null {
    const account = getAccount();
    return formatUserData(account);
}

export async function getAzureAccessToken(tokenScopes?: string[]): Promise<string | undefined> {
    const scopes = tokenScopes && tokenScopes.length > 0 ? tokenScopes : defaultAccessTokenScopes;
    const tokenResponse = await acquireTokens(scopes);
    if (tokenResponse) {
        localStorage.setItem(AUTH_KEY, tokenResponse.accessToken);
        return tokenResponse.accessToken;
    }
    return undefined;
}

// MSAL is already caching the token. This function is used for non async function calls.
export function getCachedAccessToken(): string | null {
    const token = localStorage.getItem(AUTH_KEY);
    return token;
}
