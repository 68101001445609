export const APP_NAME = "Gigamap";
export const APP_VERSION = "1.18.1";

export interface Config {
    ELASTIC_APM_ENABLED: boolean;
    ELASTIC_APM_SERVER_ORIGIN?: string;
    ELASTIC_APM_ENVIRONMENT?: string;
}

export function apiUrl(): string {
    const match = window.location.hostname.match(/localhost|\d+\.\d+\.\d+\.\d+/);
    if (match) {
        return `${window.location.protocol}//${match[0]}:5000`;
    }
    const url = new URL(window.location.toString());
    url.pathname = "/api";
    url.hash = "";
    url.search = "";
    return url.toString();
}

export function assetDbUrl(): string {
    switch (getEnvironmentFromHostname()) {
        case "production":
            return "https://assets.ext.gigaclear.net";
        case "staging":
            return "https://assets.stagingext.gigaclear.net";
        default:
            return "https://assets.aws.dev.gigaclear.net";
    }
}

export function getAuthServiceUrl(): string {
    switch (getEnvironmentFromHostname()) {
        case "production":
            return "https://auth.ext.gigaclear.net";
        case "staging":
            return "https://auth.stagingext.gigaclear.net";
        default:
            return "https://auth-service.aws.dev.gigaclear.net";
    }
}

export function isDev(): boolean {
    return (
        window.location.hostname.includes("staging") ||
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("dev")
    );
}

export function getEnvironmentFromHostname(): string {
    const hostname = window.location.hostname;
    if (hostname.includes("localhost")) {
        return "local";
    }
    if (hostname.includes("dev")) {
        return "dev";
    }
    if (hostname.includes("staging")) {
        return "staging";
    }
    return "production";
}

export async function readConfig(): Promise<Config> {
    const response = await fetch("/config.json", { cache: "no-cache" });
    if (!response.ok || response.status !== 200) {
        return {
            ELASTIC_APM_ENABLED: false,
        };
    }
    try {
        const config = (await response.json()) as Config;
        return config;
    } catch (error) {
        throw new Error(`Failed to parse config.json as JSON: ${error}`);
    }
}
