import Vue from "vue";
import VueRouter from "vue-router";

import NotFound from "@/views/not-found";
import Login from "@/views/login";
import Map from "@/views/map";
import SLD from "./components/sld";
import TopologyDiagram from "./components/topology-diagram";
import { FileViewer } from "./components/file-viewer";
import { ClosureSplicingDiagram } from "@/components/closure-splicing-diagram";
import { APP_NAME } from "@/appconfig";

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        {
            path: "/login",
            component: Login,
            meta: {
                title: `${APP_NAME}: Login`,
            },
        },
        {
            path: "/",
            component: Map,
        },

        {
            path: "*",
            component: NotFound,
            meta: {
                title: `${APP_NAME}: Not Found`,
            },
        },
        {
            path: "/sld",
            component: SLD,
        },
        {
            path: "/closure-splicing-diagram/designId=:designId/closureId=:closure_id",
            component: ClosureSplicingDiagram,
            props: true,
        },
        {
            path: "/topology-diagram/designId=:designId",
            component: TopologyDiagram,
            props: true,
        },
        {
            path: "/attachment-view/attachment_id=:attachmentId",
            component: FileViewer,
            props: true,
        },
    ],
    mode: "history",
    linkActiveClass: "active",
});
